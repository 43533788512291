import pl from "./languages/pl";
import en from "./languages/en";

export const i18nextSettings = {
  resources: {
    PL: {
      translation: pl,
    },
    EN: {
      translation: en,
    },
  },
  lng: "PL",
  fallbackLng: "PL",
  interpolation: {
    escapeValue: false,
  },
};
