const ArrowUpIcon = (props) => (
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 20L12 4M12 4L18 10M12 4L6 10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpIcon;
