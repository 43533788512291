import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogBody,
  DialogFooter,
  Option,
  Select,
  Spinner,
  Typography,
} from "@material-tailwind/react";

import BlockDescription from "../../components/BlockDescription/BlockDescription";
import {
  changeDeliveryStatus,
  getQRCodeDataById,
  getQrCodeBase64,
} from "../../api/firebase/media";
import { downloadBase64Image } from "../../utils/downloadBase64Image";
import { useAuth } from "../../context/auth/AuthContext";
import {
  DELIVERY_STATUSES,
  DELIVERY_STATUSE_IDS,
  EDIT_BLOCK_STYLES,
} from "../../constants";
import { sendDeliveryStatusEmail } from "../../api/firebase/sendEmail";

const DeliveryCard = ({ deliveryData }) => {
  const { t } = useTranslation();
  const {
    id,
    paymentTimestamp,
    deliveryFullName,
    deliveryPhoneNumber,
    deliveryEmail,
    deliveryInpostLockerId,
    orderedPlate,
    qrCodeId,
    status,
    firebaseEmail,
    firebaseUserId,
    paymentUuid,
    isProduction,
  } = deliveryData;

  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [isImageDownloading, setIsImageDownloading] = useState(false);
  const [isChangeStatusDialogShown, setIsChangeStatusDialogShown] =
    useState(false);
  const [notifyUser, setNotifyUser] = useState(true);
  const [newStatus, setNewStatus] = useState(status);

  useEffect(() => {
    if (!isChangeStatusDialogShown) {
      setNotifyUser(true);
      setNewStatus(status);
    }
  }, [isChangeStatusDialogShown, status]);

  const onStatusChange = (value) => {
    setNewStatus(value);
    setIsChangeStatusDialogShown(true);
  };

  const { currentUser } = useAuth();

  const changeStatusHandler = async () => {
    setIsStatusChanging(true);

    try {
      if (notifyUser) {
        try {
          await sendDeliveryStatusEmail({
            qrCodeId: qrCodeId,
            newStatus: newStatus,
            userEmail: firebaseEmail,
          });
          toast.success(`Email to ${currentUser.email} is sent.`);
        } catch (error) {
          toast.error(`Feild sending email: ${error.message}`);
          console.log("error:", error);
          return;
        }
      }

      try {
        await changeDeliveryStatus({ deliveryId: id, newStatus });
        toast.success(`Status is updated.`);
      } catch (error) {
        toast.error(t("toastMessages.updateDeliveryStatusFailed"));
        console.log("error:", error);
      }
    } finally {
      setIsStatusChanging(false);
      setIsChangeStatusDialogShown(false);
    }
  };

  const onClickImageDownload = async () => {
    try {
      setIsImageDownloading(true);
      const { qrCodeBase64Id } = await getQRCodeDataById(qrCodeId);
      const result = await getQrCodeBase64(qrCodeBase64Id);
      downloadBase64Image(result.base64, qrCodeId);
    } catch (error) {
      toast.error(t("toastMessages.downloadImageFailed"));
      console.log("error:", error);
    } finally {
      setIsImageDownloading(false);
    }
  };

  return (
    <div>
      <BlockDescription
        text={`Order created at ${
          paymentTimestamp
            ? moment(paymentTimestamp).format("YYYY-MM-DD HH:mm:ss")
            : "-"
        }`}
        className="text-base"
      />
      <div className={`${EDIT_BLOCK_STYLES} !px-6 !py-4 !mx-0 !my-1`}>
        <div className="flex gap-x-6 gap-y-6 flex-wrap w-full">
          <div>
            <Typography color="black" className="">
              Product
            </Typography>
            <Typography color="gray">{orderedPlate.productName}</Typography>
          </div>
          <div>
            <Typography color="black" className="">
              Recipient's shipping details
            </Typography>
            <Typography color="gray">{deliveryFullName}</Typography>
            <Typography color="gray">{deliveryEmail}</Typography>
            {deliveryPhoneNumber && (
              <Typography color="gray">phone: {deliveryPhoneNumber}</Typography>
            )}
            {deliveryInpostLockerId && (
              <Typography color="gray">
                inpost: {deliveryInpostLockerId}
              </Typography>
            )}
          </div>
          <div>
            <Typography color="black" className="">
              Related QR code
            </Typography>
            <Typography color="gray">{qrCodeId}</Typography>
          </div>
          <div>
            <Typography color="black" className="">
              Firebase
            </Typography>
            <Typography color="gray">{firebaseEmail}</Typography>
            {firebaseUserId && (
              <Typography color="gray">id: {firebaseUserId}</Typography>
            )}
          </div>
          <div>
            <Typography color="black" className="">
              Payment id
            </Typography>
            <Typography color="gray">{paymentUuid}</Typography>
          </div>
          <div>
            <Typography color="black" className="">
              Order id
            </Typography>
            <Typography color="gray">{id}</Typography>
          </div>
          <div className="flex items-end justify-center">
            {isStatusChanging ? (
              <Spinner className="h-6 w-6" />
            ) : (
              <Select
                size="md"
                label="Status"
                value={newStatus}
                onChange={onStatusChange}
              >
                <Option value={DELIVERY_STATUSE_IDS.preparation_for_delivery}>
                  {DELIVERY_STATUSES.preparation_for_delivery}
                </Option>
                <Option value={DELIVERY_STATUSE_IDS.order_has_been_shipped}>
                  {DELIVERY_STATUSES.order_has_been_shipped}
                </Option>
              </Select>
            )}
          </div>
          <div
            className={`flex w-full items-end ${
              isProduction === "true" ? "justify-start" : "justify-between"
            }`}
          >
            <Button
              size="sm"
              variant="outlined"
              onClick={onClickImageDownload}
              loading={isImageDownloading}
              disabled={isImageDownloading}
            >
              Download QR img
            </Button>
            {(!isProduction || isProduction === "false") && (
              <Chip size="md" value="test" />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={isChangeStatusDialogShown}
        handler={() => {
          setIsChangeStatusDialogShown(false);
        }}
      >
        <DialogBody className="flex flex-col gap-2 w-full justify-center items-center text-center">
          <p>
            Are you sure you want to change the delivery status to{" "}
            <span className="font-bold">{newStatus}</span>?
          </p>
          <p>
            Email: <span className="font-bold">{firebaseEmail}</span>
          </p>
        </DialogBody>
        <DialogFooter className="flex justify-between items-end">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => {
              setIsChangeStatusDialogShown(false);
            }}
            className="!cursor-pointer mr-1"
            loading={isStatusChanging}
            disabled={isStatusChanging}
          >
            <span>Cancel</span>
          </Button>
          <div className="flex flex-col gap-4">
            <Checkbox
              label={
                <Typography color="blue-gray" className="inline font-medium">
                  Notify user
                </Typography>
              }
              checked={notifyUser}
              onChange={(e) => {
                setNotifyUser(e.target.checked);
              }}
            />
            <Button
              size="sm"
              onClick={changeStatusHandler}
              className="!cursor-pointer"
              loading={isStatusChanging}
              disabled={isStatusChanging}
            >
              <span>Change status</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DeliveryCard;
