import { useCallback, useEffect, useState } from "react";
import { listenAllDeliveries } from "../../api/firebase/media";
import { Spinner } from "@material-tailwind/react";
import DeliveryCard from "./DeliveryCard";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AllDeliveries = () => {
  const { t } = useTranslation();
  const [deliveries, setDeliveries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const snapshotUpdateHandler = useCallback((snapshot) => {
    try {
      setIsLoading(true);
      const newDeliveries = [];
      snapshot.forEach((doc) => {
        newDeliveries.push({ id: doc.id, ...doc.data() });
      });
      const sortedDeliveries = newDeliveries.sort(
        (a, b) => b.paymentTimestamp - a.paymentTimestamp
      );
      setDeliveries(sortedDeliveries);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const snapshotErrorHandler = useCallback((error) => {
    console.log("error:", error);
  }, []);

  useEffect(() => {
    let unsubscribe = null;
    try {
      unsubscribe = listenAllDeliveries({
        snapshotUpdateHandler,
        snapshotErrorHandler,
      });
    } catch (error) {
      toast.error(t("toastMessages.loadDeliveriesFailed"));
      console.log("error:", error);
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [snapshotErrorHandler, snapshotUpdateHandler]);

  if (isLoading) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!deliveries.length) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        No deliviries
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {deliveries.map((deliveryData) => (
        <DeliveryCard key={deliveryData.id} deliveryData={deliveryData} />
      ))}
    </div>
  );
};

export default AllDeliveries;
