import { useEffect, useMemo, useState } from "react";
import {
  Button,
  List,
  ListItem,
  Navbar,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import {
  createQrCodesBase64,
  getFreeQrCodesBase64,
  markBase64ImagesAsPrinted,
} from "../../api/firebase/media";
import { EDIT_BLOCK_STYLES } from "../../constants";
import { useSearchParams } from "react-router-dom";
import AllPayments from "../../containers/AllPayments/AllPayments";
import AllDeliveries from "../../containers/AllDeliveries/AllDeliveries";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { downloadBase64ImageArray } from "../../utils/downloadBase64Image";
import useFetchQrCodesAmounts from "../../hooks/useFetchQrCodesAmounts";

const QR_CODES_CREATION_LIMIT = 50;
const QR_CODES_PRINT_LIMIT = 50;

const DELIVERIES_STEP_ID = "deliveries";
const PAYMENTS_STEP_ID = "payments";

export const ALUMINIUM_PLAQUE_TYPE = "ALUMINIUM_PLAQUE_TYPE";
export const ALUMINIUM_BLACK_PLAQUE_TYPE = "ALUMINIUM_BLACK_PLAQUE_TYPE";

const AdminPage = () => {
  const { t } = useTranslation();

  const [qrCodesGenerating, setQrCodesGenerating] = useState(false);
  const [qrCodesPrinting, setQrCodesPrinting] = useState(false);
  const [activeStep, setActiveStep] = useState(DELIVERIES_STEP_ID);
  const [searchParams, setSearchParams] = useSearchParams();
  const stepFromSearchParams = searchParams.get("step");

  const {
    qrCodesFetching,
    freeAluminiumQrCodesAmount,
    printedAluminiumQrCodesAmount,
    usedAluminiumQrCodesAmount,
    freeAluminiumBlackQrCodesAmount,
    printedAluminiumBlackQrCodesAmount,
    usedAluminiumBlackQrCodesAmount,
    fetchQrCodesAmounts,
  } = useFetchQrCodesAmounts();

  const isValidStep = useMemo(
    () => [DELIVERIES_STEP_ID, PAYMENTS_STEP_ID].includes(stepFromSearchParams),
    [stepFromSearchParams]
  );

  const onChangeStep = (nextStep) => () => {
    if (activeStep !== nextStep) {
      setSearchParams({ step: nextStep });
      setActiveStep(nextStep);
    }
  };

  useEffect(() => {
    if (isValidStep) {
      setActiveStep(stepFromSearchParams);
      setSearchParams({ step: stepFromSearchParams });
    } else {
      setActiveStep(DELIVERIES_STEP_ID);
      setSearchParams({ step: DELIVERIES_STEP_ID });
    }
  }, [stepFromSearchParams, isValidStep, searchParams]);

  const onCreateQrCodes =
    (plaqueType = "") =>
    async () => {
      try {
        setQrCodesGenerating(true);
        await createQrCodesBase64({
          amount: QR_CODES_CREATION_LIMIT,
          plaqueType,
        });
        await fetchQrCodesAmounts();
      } catch (error) {
        toast.error(t("toastMessages.failedtoCreateNewAvailableQRCodes"));
        console.log("error:", error);
      } finally {
        setQrCodesGenerating(false);
      }
    };

  const onPrintQrCodes =
    (plaqueType = "") =>
    async () => {
      try {
        setQrCodesPrinting(true);
        const idToBase64Map = await getFreeQrCodesBase64({
          amount: QR_CODES_PRINT_LIMIT,
          plaqueType,
        });

        downloadBase64ImageArray(idToBase64Map, plaqueType);
        // await markBase64ImagesAsPrinted(Object.keys(idToBase64Map));
        // await fetchQrCodesAmounts();
      } catch (error) {
        toast.error(t("Faild to print qr codes"));
        console.log("error:", error);
      } finally {
        setQrCodesPrinting(false);
      }
    };

  return (
    <div className="bg-[#f5f9fc] flex flex-col items-center content-height">
      <div className="pt-0 pb-10 sm:pb-20 content-width">
        <div className={`${EDIT_BLOCK_STYLES} !px-6 !py-4 !mb-0 !my-1 !mt-10`}>
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-6 w-full justify-between">
              <div className="flex gap-6">
                <div>
                  <Typography color="black" className="">
                    Free <span className="font-bold">aluminium</span> Codes
                  </Typography>
                  {qrCodesFetching || qrCodesGenerating ? (
                    <Spinner className="h-6 w-6" />
                  ) : (
                    <Typography color="gray">
                      {freeAluminiumQrCodesAmount}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Free <span className="font-bold">aluminium</span> Plaques
                  </Typography>
                  {qrCodesFetching || qrCodesGenerating ? (
                    <Spinner className="h-6 w-6" />
                  ) : (
                    <Typography color="gray">
                      {printedAluminiumQrCodesAmount}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Used <span className="font-bold">aluminium</span>{" "}
                    Codes/Plaques
                  </Typography>
                  {qrCodesFetching || qrCodesGenerating ? (
                    <Spinner className="h-6 w-6" />
                  ) : (
                    <Typography color="gray">
                      {usedAluminiumQrCodesAmount}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="flex gap-6 w-full justify-end">
                <div className="">
                  <Button
                    size="sm"
                    onClick={onCreateQrCodes(ALUMINIUM_PLAQUE_TYPE)}
                    disabled={qrCodesFetching || qrCodesGenerating}
                    loading={qrCodesFetching || qrCodesGenerating}
                  >
                    Create x{QR_CODES_CREATION_LIMIT} codes for{" "}
                    <span className="text-orange-300">aluminium</span> plaque
                  </Button>
                </div>
                <div className="">
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={onPrintQrCodes(ALUMINIUM_PLAQUE_TYPE)}
                    disabled={qrCodesFetching || qrCodesPrinting}
                    loading={qrCodesFetching || qrCodesPrinting}
                  >
                    Download x{QR_CODES_PRINT_LIMIT} codes
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6 w-full justify-between">
              <div className="flex gap-6">
                <div>
                  <Typography color="black" className="">
                    Free <span className="font-bold">aluminium black</span>{" "}
                    Codes
                  </Typography>
                  {qrCodesFetching || qrCodesGenerating ? (
                    <Spinner className="h-6 w-6" />
                  ) : (
                    <Typography color="gray">
                      {freeAluminiumBlackQrCodesAmount}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Free <span className="font-bold">aluminium black</span>{" "}
                    Plaques
                  </Typography>
                  {qrCodesFetching || qrCodesGenerating ? (
                    <Spinner className="h-6 w-6" />
                  ) : (
                    <Typography color="gray">
                      {printedAluminiumBlackQrCodesAmount}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Used <span className="font-bold">aluminium black</span>{" "}
                    Codes/Plaques
                  </Typography>
                  {qrCodesFetching || qrCodesGenerating ? (
                    <Spinner className="h-6 w-6" />
                  ) : (
                    <Typography color="gray">
                      {usedAluminiumBlackQrCodesAmount}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="flex gap-6 w-full justify-end">
                <div className="">
                  <Button
                    size="sm"
                    onClick={onCreateQrCodes(ALUMINIUM_BLACK_PLAQUE_TYPE)}
                    disabled={qrCodesFetching || qrCodesGenerating}
                    loading={qrCodesFetching || qrCodesGenerating}
                  >
                    Create x{QR_CODES_CREATION_LIMIT} codes for{" "}
                    <span className="text-orange-300">aluminium black</span>{" "}
                    plaque
                  </Button>
                </div>
                <div className="">
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={onPrintQrCodes(ALUMINIUM_BLACK_PLAQUE_TYPE)}
                    disabled={qrCodesFetching || qrCodesPrinting}
                    loading={qrCodesFetching || qrCodesPrinting}
                  >
                    Download x{QR_CODES_PRINT_LIMIT} codes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navbar className="!px-6 !py-2 bg-[#eceff199] border-none mb-14 mt-10">
          <List className="w-full grid grid-cols-1 md:grid-cols-2 !px-0 !py-0 gap-x-6 gap-y-2">
            <ListItem
              onClick={onChangeStep(DELIVERIES_STEP_ID)}
              className={`flex justify-center items-center py-2 hover:bg-white hover:rounded-md hover:shadow focus:bg-white focus:rounded-md focus:shadow active:bg-white active:rounded-md active:shadow ${
                activeStep === DELIVERIES_STEP_ID
                  ? "bg-white rounded-md shadow"
                  : ""
              }`}
            >
              Orders
            </ListItem>
            <ListItem
              onClick={onChangeStep(PAYMENTS_STEP_ID)}
              className={`flex justify-center items-center py-2 hover:bg-white hover:rounded-md hover:shadow focus:bg-white focus:rounded-md focus:shadow active:bg-white active:rounded-md active:shadow ${
                activeStep === PAYMENTS_STEP_ID
                  ? "bg-white rounded-md shadow"
                  : ""
              }`}
            >
              Payments
            </ListItem>
          </List>
        </Navbar>
        {activeStep === DELIVERIES_STEP_ID && <AllDeliveries />}
        {activeStep === PAYMENTS_STEP_ID && <AllPayments />}
      </div>
    </div>
  );
};

export default AdminPage;
