import { getAuth } from "@firebase/auth";
import {
  arrayUnion,
  doc,
  getDoc,
  collection,
  getFirestore,
  setDoc,
  updateDoc,
  query,
  where,
  onSnapshot,
  getCountFromServer,
  deleteDoc,
  getDocs,
  limit,
  arrayRemove,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import QRCode from "qrcode";

import { storage } from "../../firebase";
import { DEFAULT_DRAFT_TEXT_DATA, QR_CODES_LIMIT } from "../../constants";
import {
  ALUMINIUM_BLACK_PLAQUE_TYPE,
  ALUMINIUM_PLAQUE_TYPE,
} from "../../pages/AdminPage/AdminPage";

// TEST
// export const testDeliveriesCollection = async ({ deliveryId, newStatus }) => {
//   const db = getFirestore();

//   const docRef = doc(db, "deliveries", deliveryId);

//   await updateDoc(docRef, {
//     [`status`]: newStatus,
//   });
// };

export const createNewQR = async ({ newQRCodeId }) => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const docRef = doc(db, "qrCodesData", newQRCodeId);

  const defaultData = {
    firebaseUserId: userId,
    creationDate: new Date().getTime(),
    qrCodeBase64Id: "",
    uploadedMedia: [],
    text: DEFAULT_DRAFT_TEXT_DATA,
    usedMediaSize: 0,
  };

  await setDoc(docRef, defaultData, { merge: true });
};

export const updateText = async ({
  qrCodeId,
  fullName,
  startDate,
  endDate,
  interestingAnswers,
  achievements,
  description,
}) => {
  const db = getFirestore();

  const docRef = doc(db, "qrCodesData", qrCodeId);

  await updateDoc(docRef, {
    [`lastUpdate`]: new Date().getTime(),
    [`text.fullName`]: fullName,
    [`text.startDate`]: startDate,
    [`text.endDate`]: endDate,
    [`text.interestingAnswers`]: interestingAnswers,
    [`text.achievements`]: achievements,
    [`text.description`]: description,
  });
};

export const changeDeliveryStatus = async ({ deliveryId, newStatus }) => {
  const db = getFirestore();

  const docRef = doc(db, "deliveries", deliveryId);

  await updateDoc(docRef, {
    [`status`]: newStatus,
  });
};

export const bindQRCodeIdToUser = async ({ newQRCodeId }) => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const docRef = doc(db, "usersPool", userId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    await updateDoc(docRef, {
      [`qrCodeIds`]: arrayUnion(newQRCodeId),
    });
  } else {
    await setDoc(docRef, { qrCodeIds: [newQRCodeId] });
  }
};

export const getUserQRCodeIds = async () => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const docRef = doc(db, "usersPool", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().qrCodeIds || [];
  } else {
    return [];
  }
};

export const getUserQRCodesLimit = async () => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const docRef = doc(db, "usersPool", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().qrCodesLimit || QR_CODES_LIMIT;
  } else {
    return QR_CODES_LIMIT;
  }
};

export const getQrCodesBase64Amount = async () => {
  const db = getFirestore();

  const coll = collection(db, "qrCodesBase64");

  const freeAluminiumBase64Query = query(
    coll,
    where("plaqueType", "==", ALUMINIUM_PLAQUE_TYPE),
    where("printed", "==", false),
    where("qrCodeDataId", "==", "")
  );
  const printedAluminiumBase64Query = query(
    coll,
    where("plaqueType", "==", ALUMINIUM_PLAQUE_TYPE),
    where("printed", "==", true),
    where("qrCodeDataId", "==", "")
  );

  const freeAluminiumBlackBase64Query = query(
    coll,
    where("plaqueType", "==", ALUMINIUM_BLACK_PLAQUE_TYPE),
    where("printed", "==", false),
    where("qrCodeDataId", "==", "")
  );
  const printedAluminiumBlackBase64Query = query(
    coll,
    where("plaqueType", "==", ALUMINIUM_BLACK_PLAQUE_TYPE),
    where("printed", "==", true),
    where("qrCodeDataId", "==", "")
  );

  const allUsedBase64Query = query(coll, where("qrCodeDataId", "!=", ""));
  const allUsedBase64Snapshot = await getDocs(allUsedBase64Query);
  const allUsedBase64Data = [];

  allUsedBase64Snapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();
    allUsedBase64Data.push({ id: doc.id, ...data });
  });

  const usedAluminiumQrCodes = allUsedBase64Data.filter(
    (base64Data) => base64Data.plaqueType === ALUMINIUM_PLAQUE_TYPE
  );

  const usedAluminiumBlackQrCodes = allUsedBase64Data.filter(
    (base64Data) => base64Data.plaqueType === ALUMINIUM_BLACK_PLAQUE_TYPE
  );

  const freeAluminiumBase64Snapshot = await getCountFromServer(
    freeAluminiumBase64Query
  );
  const printedAluminiumBase64Snapshot = await getCountFromServer(
    printedAluminiumBase64Query
  );

  const freeAluminiumBlackBase64Snapshot = await getCountFromServer(
    freeAluminiumBlackBase64Query
  );
  const printedAluminiumBlackBase64Snapshot = await getCountFromServer(
    printedAluminiumBlackBase64Query
  );

  return {
    freeAluminiumQrCodesAmount: freeAluminiumBase64Snapshot.data().count,
    printedAluminiumQrCodesAmount: printedAluminiumBase64Snapshot.data().count,
    usedAluminiumQrCodesAmount: usedAluminiumQrCodes.length,

    freeAluminiumBlackQrCodesAmount:
      freeAluminiumBlackBase64Snapshot.data().count,
    printedAluminiumBlackQrCodesAmount:
      printedAluminiumBlackBase64Snapshot.data().count,
    usedAluminiumBlackQrCodesAmount: usedAluminiumBlackQrCodes.length,
  };
};

export const getFreeQrCodesBase64 = async ({ amount, plaqueType }) => {
  if (
    ![ALUMINIUM_PLAQUE_TYPE, ALUMINIUM_BLACK_PLAQUE_TYPE].includes(plaqueType)
  ) {
    throw new Error("Unknown plaqueType");
  }

  if (!amount) {
    throw new Error("Amount is not specified");
  }

  const db = getFirestore();

  const coll = collection(db, "qrCodesBase64");

  const freeBase64Query = query(
    coll,
    where("plaqueType", "==", plaqueType),
    where("printed", "==", false),
    where("qrCodeDataId", "==", ""),
    limit(amount)
  );

  const freeBase64Snapshot = await getDocs(freeBase64Query);

  const idToBase64Map = {};

  freeBase64Snapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    idToBase64Map[doc.id] = doc.data()?.base64;
  });

  return idToBase64Map;
};

export const markBase64ImagesAsPrinted = async (qrCodeBase64IdsArray = []) => {
  const db = getFirestore();
  const markCodeBase64AsPrintedPromises = qrCodeBase64IdsArray.map((id) => {
    console.log("id:", id);

    return (async () => {
      const docRef = doc(db, "qrCodesBase64", id);
      await updateDoc(docRef, {
        [`printed`]: true,
      });
    })();
  });

  await Promise.all(markCodeBase64AsPrintedPromises);
};

export const getUserQrCodesDataAmount = async (userId) => {
  const db = getFirestore();

  const coll = collection(db, "qrCodesData");
  const qrCodesDataQuery = query(coll, where("firebaseUserId", "==", userId));
  const qrCodesDataQuerySnapshot = await getCountFromServer(qrCodesDataQuery);

  return qrCodesDataQuerySnapshot?.data()?.count ?? 0;
};

export const createQrCodesBase64 = async ({ amount = 1, plaqueType = "" }) => {
  const db = getFirestore();

  const createBase64Promises = Array(amount)
    .fill(null)
    .map(() => {
      return (async () => {
        const qrCodeBase64Id = uuidv4();
        const qrCodeBase64 = await QRCode.toDataURL(
          `https://echoqr.pl/${qrCodeBase64Id}/view`,
          {
            type: "image/png",
            errorCorrectionLevel: "H", // L, M, Q, H
            margin: 1,
            width: 500,
            // color: {
            //   dark: "#ffffff",
            //   light: "#000000",
            // },
          }
        );

        const docRef = doc(db, "qrCodesBase64", qrCodeBase64Id);

        await setDoc(
          docRef,
          {
            base64: qrCodeBase64,
            qrCodeDataId: "",
            plaqueType,
            printed: false,
          },
          { merge: true }
        );
      })();
    });

  await Promise.all(createBase64Promises);
};

export const getQrCodeBase64 = async (qrCodeBase64Id) => {
  const db = getFirestore();

  const docRef = doc(db, "qrCodesBase64", qrCodeBase64Id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() ?? {};
  } else {
    return {};
  }
};

export const listenUserPayments = ({
  snapshotUpdateHandler,
  snapshotErrorHandler,
}) => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const paymentsCollection = collection(db, "payments");
  const paymentsQuery = query(
    paymentsCollection,
    where("firebaseUserId", "==", userId)
  );

  return onSnapshot(paymentsQuery, snapshotUpdateHandler, snapshotErrorHandler);
};

export const listenAllPayments = ({
  snapshotUpdateHandler,
  snapshotErrorHandler,
}) => {
  const db = getFirestore();
  const paymentsCollection = collection(db, "payments");
  return onSnapshot(
    paymentsCollection,
    snapshotUpdateHandler,
    snapshotErrorHandler
  );
};

export const listenUserQrCodes = ({
  snapshotUpdateHandler,
  snapshotErrorHandler,
}) => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const qrCodesDataCollection = collection(db, "qrCodesData");
  const qrCodesDataQuery = query(
    qrCodesDataCollection,
    where("firebaseUserId", "==", userId)
  );

  return onSnapshot(
    qrCodesDataQuery,
    snapshotUpdateHandler,
    snapshotErrorHandler
  );
};

export const listenUserDeliveries = ({
  snapshotUpdateHandler,
  snapshotErrorHandler,
}) => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const deliveriesCollection = collection(db, "deliveries");
  const deliveriesQuery = query(
    deliveriesCollection,
    where("firebaseUserId", "==", userId)
  );

  return onSnapshot(
    deliveriesQuery,
    snapshotUpdateHandler,
    snapshotErrorHandler
  );
};

export const listenAllDeliveries = ({
  snapshotUpdateHandler,
  snapshotErrorHandler,
}) => {
  const db = getFirestore();
  const deliveriesCollection = collection(db, "deliveries");
  return onSnapshot(
    deliveriesCollection,
    snapshotUpdateHandler,
    snapshotErrorHandler
  );
};

export const getQRCodeDataById = async (qrCodeId) => {
  const db = getFirestore();

  const docRef = doc(db, "qrCodesData", qrCodeId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    return data ? { ...data, id: qrCodeId } : {};
  } else {
    return {};
  }
};

export const setMediaDescription = async ({
  qrCodeId,
  fileName,
  description,
}) => {
  const db = getFirestore();

  const docRef = doc(db, "qrCodesData", qrCodeId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    const mediaIndexToUpdate = (data.uploadedMedia || []).findIndex(
      (media) => media.fileName === fileName
    );

    if (mediaIndexToUpdate >= -1) {
      const newUploadedMedia = [...data.uploadedMedia];
      newUploadedMedia[mediaIndexToUpdate].description = description;

      await updateDoc(docRef, {
        [`lastUpdate`]: new Date().getTime(),
        [`uploadedMedia`]: newUploadedMedia,
      });
    } else {
      console.log("media didn't find");
    }
  } else {
    console.log("qrCodeId didn't find");
  }
};

export const bindUploadedMediaToQRCode = async ({
  qrCodeId,
  size,
  type,
  name,
  url,
}) => {
  const db = getFirestore();

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const docRef = doc(db, "qrCodesData", qrCodeId);
  const docSnap = await getDoc(docRef);

  const dataToSave = {
    url,
    userId,
    fileSize: size,
    fileType: type,
    fileName: name,
    uploadDate: new Date().getTime(),
  };

  if (docSnap.exists()) {
    const data = docSnap.data();
    const newUsedMediaSize = size + (data.usedMediaSize || 0);

    await updateDoc(docRef, {
      [`lastUpdate`]: new Date().getTime(),
      [`uploadedMedia`]: arrayUnion(dataToSave),
      [`usedMediaSize`]: newUsedMediaSize,
    });
  } else {
    throw new Error("document does not exiist");
  }
};

export const deleteMediaFromQRCode = async ({
  qrCodeId,
  fileName,
  fileSize,
}) => {
  const db = getFirestore();

  const docRef = doc(db, "qrCodesData", qrCodeId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    const newUploadedMedia = data.uploadedMedia.filter(
      (media) => media.fileName !== fileName
    );
    const newUsedMediaSize = (data.usedMediaSize || 0) - fileSize;

    if (newUploadedMedia.length === data.uploadedMedia.length - 1) {
      await updateDoc(docRef, {
        [`lastUpdate`]: new Date().getTime(),
        [`uploadedMedia`]: newUploadedMedia,
        [`usedMediaSize`]: newUsedMediaSize,
      });
    } else {
      console.log("media didn't find");
    }
  } else {
    console.log("qrCodeId didn't find");
  }
};

export const deleteMediaFromStorage = async (fileName) => {
  const storageRef = ref(storage, `uploads/${fileName}`);

  await deleteObject(storageRef);
};

export const deleteQrCodeData = async (qrCodeDataId) => {
  const qrCodeData = await getQRCodeDataById(qrCodeDataId);
  const uploadedMedia = qrCodeData.uploadedMedia ?? [];

  const deleteMediaFromStoragePromises = uploadedMedia.map((fileData) => {
    return deleteMediaFromStorage(fileData.fileName);
  });

  await Promise.all(deleteMediaFromStoragePromises);

  const db = getFirestore();

  await deleteDoc(doc(db, "qrCodesData", qrCodeDataId));

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  const docRef = doc(db, "usersPool", userId);
  await updateDoc(docRef, {
    [`qrCodeIds`]: arrayRemove(qrCodeDataId),
  });
};
