import { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import { PATH_NAMES } from "../../routes";
import Footer from "../Footer/Footer";
import { IconButton } from "@material-tailwind/react";
import ArrowUpIcon from "../../svg/ArrowUpIcon";
import { useTranslation } from "react-i18next";
import { LANGUAGES, LANGUAGE_STORAGE_KEY } from "../../constants";
import "../../global.css";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

const Layout = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [upButtonIsShown, setUpButtonIsShown] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const withoutHeader =
    pathname.includes(PATH_NAMES.preview) || pathname.includes(PATH_NAMES.view);

  const isMain = pathname === "/";
  const isView = useMemo(
    () =>
      pathname.endsWith("/view") ||
      pathname.endsWith("/view/") ||
      pathname.endsWith("/preview") ||
      pathname.endsWith("/preview/"),
    [pathname]
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        if (!upButtonIsShown) {
          setUpButtonIsShown(true);
        }
      } else if (upButtonIsShown) {
        setUpButtonIsShown(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [upButtonIsShown]);

  useEffect(() => {
    const lang = localStorage.getItem(LANGUAGE_STORAGE_KEY);
    i18n.changeLanguage(lang || LANGUAGES.PL);
  }, []);

  // TEST
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       await testDeliveriesCollection({
  //         deliveryId: "012af8d6-6ef1-46b8-b2eb-9b428d2ab61c",
  //         newStatus: "111222",
  //       });
  //     } catch (error) {
  //       console.log("testDeliveriesCollection ERROR:", error);
  //     }
  //   })();
  // }, []);

  return (
    <ErrorBoundary>
      <div>
        {!isMain && (
          <img
            className="absolute w-[500px] h-[250px] sm:w-[800px] sm:h-[350px] lg:w-[1150px] lg:h-[400px] pointer-events-none"
            src="https://firebasestorage.googleapis.com/v0/b/echo-project-bc60a.appspot.com/o/echolightimage4.png?alt=media&token=f1ecad95-7b28-4852-b605-d1c18dea0ff1"
            alt="światło z niebios"
          />
        )}

        {!withoutHeader && <Header />}

        <div
          style={{
            overflowX: "hidden",
          }}
        >
          <Outlet />
        </div>
        <Footer isShort={isView} />
        {upButtonIsShown && (
          <>
            <IconButton
              className="rounded-full !fixed right-0 bottom-[30%] opacity-100 block 2xl:hidden z-[10]"
              onClick={scrollToTop}
            >
              <ArrowUpIcon className="w-7 h-7" />
            </IconButton>
            <IconButton
              className="rounded-full hidden 2xl:!fixed bottom-[30%] opacity-100 z-[10] fixed-preview-button-width 2xl:!flex justify-end"
              onClick={scrollToTop}
            >
              <ArrowUpIcon className="w-7 h-7" />
            </IconButton>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Layout;
