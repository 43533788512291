import React from "react";
import i18n from "i18next";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import { ToastContainer } from "react-toastify";
import Analytics from "react-ga4";
import { AuthProvider } from "./context/auth/AuthContext";
import { QRCodeDataProvider } from "./context/qrCodeData/QRCodeDataContext";
import { LoginDialogProvider } from "./context/loginDialog/LoginDialogContext";
import { i18nextSettings } from "./i18nextSettings";
import routes from "./routes";

import "./global.css";
import "./tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/fonts/fonts.css";

i18n.use(initReactI18next).init(i18nextSettings);

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter(routes);

Analytics.initialize("G-3HH0XP0ZXM");

root.render(
  <AuthProvider>
    <QRCodeDataProvider>
      <LoginDialogProvider>
        <RouterProvider router={router} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={true}
          draggable={false}
          theme="light"
        />
      </LoginDialogProvider>
    </QRCodeDataProvider>
  </AuthProvider>
);
