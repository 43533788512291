const PlusIcon = (props) => (
  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4 12H20M12 4V20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
