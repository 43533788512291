import { useCallback, useEffect, useState } from "react";
import { Spinner, Typography } from "@material-tailwind/react";
import moment from "moment";
import { toast } from "react-toastify";

import BlockDescription from "../../components/BlockDescription/BlockDescription";
import { listenUserDeliveries } from "../../api/firebase/media";
import { DELIVERY_STATUSE_IDS, EDIT_BLOCK_STYLES } from "../../constants";
import { useAnalyticsEvent, useAnalyticsPage } from "../../hooks/useAnalytics";
import { useTranslation } from "react-i18next";

const Deliveries = () => {
  const { t } = useTranslation();
  useAnalyticsPage();
  const { sendEvent } = useAnalyticsEvent();

  const [deliveries, setDeliveries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const snapshotUpdateHandler = useCallback((snapshot) => {
    try {
      setIsLoading(true);
      const newDeliveries = [];
      snapshot.forEach((doc) => {
        newDeliveries.push({ id: doc.id, ...doc.data() });
      });
      const sortedDeliveries = newDeliveries.sort(
        (a, b) => b.paymentTimestamp - a.paymentTimestamp
      );
      setDeliveries(sortedDeliveries);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const snapshotErrorHandler = useCallback((error) => {
    console.log("error:", error);
  }, []);

  useEffect(() => {
    let unsubscribe = null;
    try {
      unsubscribe = listenUserDeliveries({
        snapshotUpdateHandler,
        snapshotErrorHandler,
      });
    } catch (error) {
      sendEvent("error_load_deliveries");

      toast.error(t("toastMessages.loadDeliveriesFailed"));
      console.log("error:", error);
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [snapshotErrorHandler, snapshotUpdateHandler]);

  if (isLoading) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!deliveries.length) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        {t("deliveries.noDeliveries")}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10">
      {deliveries.map(
        ({
          id,
          paymentTimestamp,
          deliveryFullName,
          deliveryPhoneNumber,
          deliveryEmail,
          deliveryInpostLockerId,
          orderedPlate,
          qrCodeId,
          status,
        }) => (
          <div key={id}>
            <BlockDescription
              text={`${t("deliveries.orderCreatedAt")} ${
                paymentTimestamp
                  ? moment(paymentTimestamp).format("YYYY-MM-DD HH:mm:ss")
                  : "-"
              }`}
              className="text-base"
            />
            <div className={`${EDIT_BLOCK_STYLES} !px-6 !py-4 !mx-0 !my-1`}>
              <div className="flex gap-x-6 gap-y-6 flex-wrap w-full">
                <div>
                  <Typography color="black" className="">
                    {t("deliveries.product")}
                  </Typography>
                  <Typography color="gray">
                    {orderedPlate.productName}
                  </Typography>
                </div>
                <div>
                  <Typography color="black" className="">
                    {t("deliveries.deliveryInformation")}
                  </Typography>
                  <Typography color="gray">{deliveryFullName}</Typography>
                  <Typography color="gray">{deliveryEmail}</Typography>
                  {deliveryPhoneNumber && (
                    <Typography color="gray">
                      {t("deliveries.phone")} {deliveryPhoneNumber}
                    </Typography>
                  )}
                  {deliveryInpostLockerId && (
                    <Typography color="gray">
                      InPost: {deliveryInpostLockerId}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    {t("deliveries.relatedQRCode")}
                  </Typography>
                  <Typography color="gray">{qrCodeId}</Typography>
                </div>
                <div>
                  <Typography color="black" className="">
                    {t("deliveries.status")}
                  </Typography>
                  {DELIVERY_STATUSE_IDS.preparation_for_delivery === status && (
                    <Typography color="blue" className="font-semibold">
                      {t(`deliveries.${status}`)}
                    </Typography>
                  )}
                  {DELIVERY_STATUSE_IDS.order_has_been_shipped === status && (
                    <Typography color="green" className="font-semibold">
                      {t(`deliveries.${status}`)}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Deliveries;
