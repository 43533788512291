import { useCallback, useEffect } from "react";
import Analytics from "react-ga4";
import { isProduction } from "../utils/isProduction";

export const useAnalyticsPage = () => {
  const isProd = isProduction();
  useEffect(() => {
    if (isProd) {
      Analytics.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [isProd]);
};

export const useAnalyticsEvent = () => {
  const isProd = isProduction();

  const sendEvent = useCallback(
    (actionName) => {
      if (isProd) {
        Analytics.event(actionName);
      }
    },
    [isProd]
  );

  return { sendEvent };
};
