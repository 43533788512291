import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ListItem,
  Typography,
  List,
  Navbar,
  Alert,
} from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

import QRCodes from "../QRCodes/QRCodes";
import AccountDetails from "../AccountDetails/AccountDetails";
import Deliveries from "../Deliveries/Deliveries";
import SuccessIcon from "../../svg/SuccessIcon";
import Payments from "../Payments/Payments";
import { useAnalyticsEvent } from "../../hooks/useAnalytics";

import "../../global.css";
// import { sendDeliveryStatusEmail } from "../../api/firebase/sendEmail";
// import { DELIVERY_STATUSE_IDS } from "../../constants";
import { useAuth } from "../../context/auth/AuthContext";

export const QR_CODES_STEP_ID = "qrcodes";
export const DELIVERIES_STEP_ID = "deliveries";
export const PAYMENTS_STEP_ID = "payments";
export const SETTINGS_STEP_ID = "account_details";

const AccountMenu = () => {
  const { t } = useTranslation();

  const { sendEvent } = useAnalyticsEvent();

  const [activeStep, setActiveStep] = useState(QR_CODES_STEP_ID);
  const [searchParams, setSearchParams] = useSearchParams();
  const stepFromSearchParams = searchParams.get("step");

  const isValidStep = useMemo(
    () =>
      [
        QR_CODES_STEP_ID,
        DELIVERIES_STEP_ID,
        PAYMENTS_STEP_ID,
        SETTINGS_STEP_ID,
      ].includes(stepFromSearchParams),
    [stepFromSearchParams]
  );

  const onChangeStep = (nextStep) => () => {
    if (activeStep !== nextStep) {
      setSearchParams({ step: nextStep });
      setActiveStep(nextStep);
    }
  };

  const isPlateOrdered = Boolean(searchParams.get("qrCodeId"));

  const [isOrderedMessageOpen, setIsOrderedMessageOpen] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (isPlateOrdered) {
      sendEvent("plate_is_ordered_alert_message");

      setIsOrderedMessageOpen(true);

      // (async () => {
      //   try {
      //     await sendDeliveryStatusEmail({
      //       qrCodeId: searchParams.get("qrCodeId"),
      //       newStatus: DELIVERY_STATUSE_IDS.preparation_for_delivery,
      //       userEmail: currentUser.email,
      //     });
      //   } catch (error) {
      //     sendEvent("error_send_email_that_order_preparing");
      //   }
      // })();

      searchParams.delete("qrCodeId");
    }

    if (isValidStep) {
      setActiveStep(stepFromSearchParams);
      setSearchParams({ step: stepFromSearchParams });
    } else {
      setSearchParams({ step: QR_CODES_STEP_ID });
      setActiveStep(QR_CODES_STEP_ID);
    }
  }, [
    stepFromSearchParams,
    isValidStep,
    searchParams,
    isPlateOrdered,
    currentUser,
  ]);

  return (
    <div className="content-width content-height w-full">
      <div className={isOrderedMessageOpen ? "mb-10" : ""}>
        <Alert
          open={isOrderedMessageOpen}
          icon={<SuccessIcon className="w-7 h-7" />}
          onClose={() => setIsOrderedMessageOpen(false)}
          className="mb-6 py-3 bg-[#0d9488] text-white"
        >
          <Typography variant="h5">
            {t("successOrder.qrCodeGenerated")}
          </Typography>
          <Typography className="mt-1 font-normal leading-5">
            {t("successOrder.checkStatus")}
          </Typography>
        </Alert>
      </div>

      <Navbar className="!px-6 !py-2 bg-[#eceff199] border-none mb-14">
        <List className="w-full grid grid-cols-1 md:grid-cols-4 !px-0 !py-0 gap-x-6 gap-y-2">
          <ListItem
            onClick={onChangeStep(QR_CODES_STEP_ID)}
            className={`flex justify-center items-center py-2 hover:bg-white hover:rounded-md hover:shadow focus:bg-white focus:rounded-md focus:shadow active:bg-white active:rounded-md active:shadow ${
              activeStep === QR_CODES_STEP_ID
                ? "bg-white rounded-md shadow"
                : ""
            }`}
          >
            {t("account.menuItems.qrCodes")}
          </ListItem>
          <ListItem
            onClick={onChangeStep(DELIVERIES_STEP_ID)}
            className={`flex justify-center items-center py-2 hover:bg-white hover:rounded-md hover:shadow focus:bg-white focus:rounded-md focus:shadow active:bg-white active:rounded-md active:shadow ${
              activeStep === DELIVERIES_STEP_ID
                ? "bg-white rounded-md shadow"
                : ""
            }`}
          >
            {t("account.menuItems.deliveries")}
          </ListItem>
          <ListItem
            onClick={onChangeStep(PAYMENTS_STEP_ID)}
            className={`flex justify-center items-center py-2 hover:bg-white hover:rounded-md hover:shadow focus:bg-white focus:rounded-md focus:shadow active:bg-white active:rounded-md active:shadow ${
              activeStep === PAYMENTS_STEP_ID
                ? "bg-white rounded-md shadow"
                : ""
            }`}
          >
            {t("account.menuItems.payments")}
          </ListItem>
          <ListItem
            onClick={onChangeStep(SETTINGS_STEP_ID)}
            className={`flex justify-center items-center text-center py-2 hover:bg-white hover:rounded-md hover:shadow focus:bg-white focus:rounded-md focus:shadow active:bg-white active:rounded-md active:shadow ${
              activeStep === SETTINGS_STEP_ID
                ? "bg-white rounded-md shadow"
                : ""
            }`}
          >
            {t("account.menuItems.accountDetails")}
          </ListItem>
        </List>
      </Navbar>

      {activeStep === QR_CODES_STEP_ID && <QRCodes />}
      {activeStep === DELIVERIES_STEP_ID && <Deliveries />}
      {activeStep === PAYMENTS_STEP_ID && <Payments />}
      {activeStep === SETTINGS_STEP_ID && <AccountDetails />}
    </div>
  );
};

export default AccountMenu;
