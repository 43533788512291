import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  IconButton,
  Collapse,
  Navbar,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
} from "@material-tailwind/react";
import { getAuth, signOut } from "@firebase/auth";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useAuth } from "../../context/auth/AuthContext";
import { PATH_NAMES } from "../../routes";
import { LoginDialogContext } from "../../context/loginDialog/LoginDialogContext";
import SignIn from "../../components/SignIn/SignIn";
import { isAdmin } from "../../utils/isAdmin";
import { isProduction } from "../../utils/isProduction";
import { LANGUAGES, LANGUAGE_STORAGE_KEY } from "../../constants";
import LanguageIcon from "../../svg/LanguageIcon";
import { useAnalyticsEvent } from "../../hooks/useAnalytics";
import ChevronDownIcon from "../../svg/ChevronDownIcon";
import {
  DELIVERIES_STEP_ID,
  PAYMENTS_STEP_ID,
  QR_CODES_STEP_ID,
  SETTINGS_STEP_ID,
} from "../AccountMenu/AccountMenu";

function AccountMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const accountMenuItems = useMemo(
    () => [
      {
        title: t("account.menuItems.qrCodes"),
        to: `${PATH_NAMES.account}?step=${QR_CODES_STEP_ID}`,
      },
      {
        title: t("account.menuItems.deliveries"),
        to: `${PATH_NAMES.account}?step=${DELIVERIES_STEP_ID}`,
      },
      {
        title: t("account.menuItems.payments"),
        to: `${PATH_NAMES.account}?step=${PAYMENTS_STEP_ID}`,
      },
      {
        title: t("account.menuItems.accountDetails"),
        to: `${PATH_NAMES.account}?step=${SETTINGS_STEP_ID}`,
      },
    ],
    [t]
  );

  const renderItems = accountMenuItems.map(({ title, to }) => (
    <NavLink to={to} key={title} className="flex items-center">
      <Button
        variant="text"
        className="flex items-center gap-3 text-sm font-normal normal-case text-base"
        size="sm"
      >
        {title}
      </Button>
    </NavLink>
  ));

  return (
    <>
      <Menu
        allowHover
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 15 }}
        placement="bottom"
      >
        <MenuHandler>
          <div>
            <Button
              variant="text"
              className="hidden xl:flex items-center gap-3 text-sm font-normal normal-case text-base "
              onClick={() => navigate(PATH_NAMES.account)}
              size="sm"
            >
              {t("header.account")}
              <ChevronDownIcon
                className={`hidden h-4 w-4 transition-transform xl:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                className={`block h-4 w-4 transition-transform xl:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </Button>
            <Button
              variant="text"
              className="flex xl:hidden items-center gap-3 text-sm font-normal normal-case text-base"
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
              size="sm"
            >
              {t("header.account")}
              <ChevronDownIcon
                className={`hidden h-4 w-4 transition-transform xl:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                className={`block h-4 w-4 transition-transform xl:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </Button>
          </div>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl xl:block bg-[#f5f9fc]">
          <ul className="grid grid-cols-4 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block xl:hidden">
        <Collapse open={isMobileMenuOpen} className="flex flex-col gap-2 pl-4">
          {renderItems}
        </Collapse>
      </div>
    </>
  );
}

const Header = () => {
  const { sendEvent } = useAnalyticsEvent();

  const { t, i18n } = useTranslation();

  const { currentUser } = useAuth();

  const [openNav, setOpenNav] = useState(false);

  const { isSignInOpened, openSignInDialog, closeSignInDialog } =
    useContext(LoginDialogContext);

  const handleOpenSignIn = () => {
    isSignInOpened ? closeSignInDialog() : openSignInDialog();
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 1140 && setOpenNav(false)
    );
  }, []);

  const location = useLocation();

  useEffect(() => {
    setOpenNav(false);
  }, [location.pathname, location.search]);

  const closeMobileNav = useCallback(() => {
    setOpenNav(false);
  }, []);

  const auth = getAuth();

  const [isSigningIn, setIsSigningIn] = useState(false);

  const [_, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      try {
        setIsSigningIn(true);
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem("emailForSignIn");

          if (!email) {
            email = window.prompt("Please provide your email for confirmation");
          }

          await signInWithEmailLink(auth, email, window.location.href);
          window.localStorage.removeItem("emailForSignIn");
          toast.success(t("toastMessages.signInSuccess"));

          sendEvent("success_sign_in");

          setSearchParams({});
        }
      } catch (error) {
        sendEvent("error_sign_in");

        toast.error(t("toastMessages.signInFailed"));

        setSearchParams({});
      } finally {
        setIsSigningIn(false);
      }
    })();
  }, []);

  const [language, setLanguage] = useState(LANGUAGES.PL);

  const onChangeLanguage = useCallback((lang) => {
    sendEvent("change_language");

    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, lang);
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem(LANGUAGE_STORAGE_KEY);
    setLanguage(lang || LANGUAGES.PL);
  }, []);

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 xl:mb-0 xl:mt-0 xl:flex-row xl:items-center xl:gap-1">
      {isAdmin(currentUser?.uid) && (
        <div>
          <NavLink to={PATH_NAMES.admin} className="flex items-center">
            <Button
              variant="text"
              className="flex items-center gap-3 text-sm font-normal normal-case text-base"
              size="sm"
            >
              {t("header.admin")}
            </Button>
          </NavLink>
        </div>
      )}

      <div>
        <NavLink to={PATH_NAMES.prices} className="flex items-center">
          <Button
            variant="text"
            className="flex items-center gap-3 text-sm font-normal normal-case  text-base"
            size="sm"
          >
            {t("header.prices")}
          </Button>
        </NavLink>
      </div>

      <div>
        <NavLink to={PATH_NAMES.howitworks} className="flex items-center">
          <Button
            variant="text"
            className="flex items-center gap-3 text-sm font-normal normal-case  text-base"
            size="sm"
          >
            {t("header.howItWorks")}
          </Button>
        </NavLink>
      </div>

      {currentUser && <AccountMenu />}
    </ul>
  );

  const onLogoutClick = async () => {
    try {
      sendEvent("click_log_out");

      await signOut(auth);
    } catch (error) {
      sendEvent("error_log_out");

      console.error("Error signing out: ", error);
    }
  };

  return (
    <>
      <Navbar className="sticky top-0 z-[100] !px-0 h-max max-w-full rounded-none border-none py-1 !bg-opacity-70 !backdrop-saturate-200 !backdrop-blur-2xl">
        <div className="flex w-full justify-center">
          <div className="flex  w-full items-center justify-between text-blue-gray-900 content-width">
            <div className="flex items-end gap-2">
              <NavLink exact="true" to="/">
                <img
                  src="/echo_qr_logo_2.png"
                  alt="Echo QR logo. Serwis umożliwiający stworzenie tabliczki z kodem QR, którą można umieścić na pomniku lub nagrobku"
                  className="w-[122px] md:w-[158px] py-1"
                />
              </NavLink>
              {!isProduction() && (
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal italic pr-2 text-ellipsis overflow-hidden max-w-[140px] sm:max-w-[250px]"
                >
                  stg
                </Typography>
              )}
            </div>

            <div className="flex items-center gap-1">
              <div className="mr-1 hidden xl:block">{navList}</div>
              <Menu allowHover offset={{ mainAxis: 15 }} placement="bottom">
                <MenuHandler>
                  <Button
                    variant="text"
                    className="flex items-center gap-2 text-sm font-normal normal-case"
                    size="sm"
                  >
                    {language} <LanguageIcon className="w-4 h-4" />
                  </Button>
                </MenuHandler>
                <MenuList className="rounded-xl bg-[#f5f9fc] min-w-fit">
                  {Object.keys(LANGUAGES)
                    .filter((lang) => lang !== language)
                    .map((lang) => (
                      <Button
                        key={lang}
                        onClick={() => onChangeLanguage(lang)}
                        variant="text"
                        className="flex items-center gap-3 text-sm font-normal normal-case"
                        size="sm"
                      >
                        {lang}
                      </Button>
                    ))}
                </MenuList>
              </Menu>
              <div className="flex items-center gap-x-1">
                {currentUser ? (
                  <div className="flex items-center">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal italic pr-2 text-ellipsis overflow-hidden max-w-[140px] sm:max-w-[250px]"
                    >
                      {(currentUser.email ?? "").split("@")[[0]]}
                    </Typography>
                    <Button
                      variant="text"
                      size="sm"
                      className="hidden xl:inline-block"
                      onClick={onLogoutClick}
                    >
                      <span>{t("header.logOut")}</span>
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="gradient"
                    size="sm"
                    className="hidden xl:flex items-center gap-4"
                    onClick={handleOpenSignIn}
                    loading={isSigningIn}
                    disabled={isSigningIn}
                  >
                    <span>
                      <span>{t("header.signIn")}</span>
                    </span>
                  </Button>
                )}
              </div>
              <IconButton
                variant="text"
                className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent xl:hidden"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <svg
                    fill="none"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </IconButton>
            </div>
          </div>
        </div>
        <Collapse open={openNav}>
          <div className="flex w-full justify-center">
            <div className="w-full content-width">{navList}</div>
          </div>
          <div className="flex w-full justify-center">
            <div className="flex items-center gap-x-4 w-full content-width">
              {currentUser ? (
                <Button
                  fullWidth
                  variant="text"
                  size="sm"
                  onClick={onLogoutClick}
                >
                  <span>
                    <span>{t("header.logOut")}</span>
                  </span>
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="gradient"
                  size="sm"
                  className="flex items-center gap-4 justify-center"
                  onClick={handleOpenSignIn}
                  loading={isSigningIn}
                  disabled={isSigningIn}
                >
                  <span>
                    <span>{t("header.signIn")}</span>
                  </span>
                </Button>
              )}
            </div>
          </div>
        </Collapse>
      </Navbar>
      <SignIn
        closeMobileNav={closeMobileNav}
        isOpened={isSignInOpened}
        open={handleOpenSignIn}
      />
    </>
  );
};

export default Header;
