import { getApps } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

export const sendDeliveryStatusEmail = async ({
  qrCodeId,
  newStatus,
  userEmail,
}) => {
  const app = getApps()[0];
  const functions = getFunctions(app, "europe-central2");

  const sendDeliveryStatusEmailToUser = httpsCallable(
    functions,
    "sendDeliveryStatusEmailToUser"
  );

  await sendDeliveryStatusEmailToUser({
    qrCodeId: qrCodeId,
    newStatus: newStatus,
    userEmail: userEmail,
  });
};
