import { useCallback, useEffect, useState } from "react";
import { listenAllPayments } from "../../api/firebase/media";
import { Chip, Spinner, Typography } from "@material-tailwind/react";
import BlockDescription from "../../components/BlockDescription/BlockDescription";
import { EDIT_BLOCK_STYLES } from "../../constants";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AllPayments = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const snapshotUpdateHandler = useCallback((snapshot) => {
    try {
      setIsLoading(true);
      const newPayments = [];
      snapshot.forEach((doc) => {
        newPayments.push({ id: doc.id, ...doc.data() });
      });
      const sortedPayments = newPayments.sort(
        (a, b) => b.paymentTimestamp - a.paymentTimestamp
      );
      setPayments(sortedPayments);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const snapshotErrorHandler = useCallback((error) => {
    console.log("error:", error);
  }, []);

  useEffect(() => {
    let unsubscribe = null;
    try {
      unsubscribe = listenAllPayments({
        snapshotUpdateHandler,
        snapshotErrorHandler,
      });
    } catch (error) {
      toast.error(t("toastMessages.loadPaymentsFailed"));
      console.log("error:", error);
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [snapshotErrorHandler, snapshotUpdateHandler]);

  if (isLoading) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!payments.length) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        No payments
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {payments.map(
        ({
          boughtProducts,
          cost,
          qrCodeId,
          paymentUuid,
          paymentTimestamp,
          customerEmail,
          customerId,
          customerName,
          firebaseEmail,
          firebaseUserId,
          deliveryFullName,
          deliveryEmail,
          deliveryPhoneNumber,
          deliveryInpostLockerId,
          isProduction,
        }) => (
          <div key={paymentUuid}>
            <BlockDescription
              text={
                paymentTimestamp
                  ? moment(paymentTimestamp).format("YYYY-MM-DD HH:mm:ss")
                  : "-"
              }
            />
            <div className={`${EDIT_BLOCK_STYLES} !px-6 !py-4 !mx-0 !my-1`}>
              <div className="flex gap-x-10 gap-y-6 flex-wrap w-full">
                <div>
                  <Typography color="black" className="">
                    Bought products
                  </Typography>
                  {boughtProducts.map(({ productName, productId }) => (
                    <Typography key={productId} color="gray">
                      {productName}
                    </Typography>
                  ))}
                </div>
                <div>
                  <Typography color="black" className="">
                    Paid price
                  </Typography>
                  <Typography color="gray">{cost.toUpperCase()}</Typography>
                </div>
                <div>
                  <Typography color="black" className="">
                    Customer
                  </Typography>
                  <Typography color="gray">{customerName}</Typography>
                  <Typography color="gray">{customerEmail}</Typography>
                  {customerId && (
                    <Typography color="gray">stripe: {customerId}</Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Firebase
                  </Typography>
                  <Typography color="gray">{firebaseEmail}</Typography>
                  {firebaseUserId && (
                    <Typography color="gray">id: {firebaseUserId}</Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Payment id
                  </Typography>
                  <Typography color="gray">{paymentUuid}</Typography>
                </div>
                <div>
                  <Typography color="black" className="">
                    Delivery
                  </Typography>
                  <Typography color="gray">{deliveryFullName}</Typography>
                  <Typography color="gray">{deliveryEmail}</Typography>
                  {deliveryPhoneNumber && (
                    <Typography color="gray">
                      phone: {deliveryPhoneNumber}
                    </Typography>
                  )}
                  {deliveryInpostLockerId && (
                    <Typography color="gray">
                      inpost: {deliveryInpostLockerId}
                    </Typography>
                  )}
                </div>
                <div>
                  <Typography color="black" className="">
                    Related qr code
                  </Typography>
                  <Typography color="gray">{qrCodeId}</Typography>
                </div>
                {(!isProduction || isProduction === "false") && (
                  <div className="flex w-full justify-end">
                    <Chip size="md" value="test" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AllPayments;
