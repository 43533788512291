import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getQRCodeDataById,
  getQrCodeBase64,
  getUserQRCodeIds,
} from "../../api/firebase/media";
import { getMBsFromBytes } from "../../utils/getMBsFromBytes";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { useAnalyticsEvent } from "../../hooks/useAnalytics";
import { useTranslation } from "react-i18next";

export const QRCodeDataContext = createContext();

export const QRCodeDataProvider = ({ children }) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalyticsEvent();

  const [qrCodeData, setQRCodeData] = useState({});
  const [isFetchingQRCodeData, setIsFetchingQRCodeData] = useState(false);
  const [usedMemorySize, setUsedMemorySize] = useState(0);
  const [qrCodeDataError, setQrCodeDataError] = useState("");

  const { uploadedMedia = [] } = qrCodeData;

  useEffect(() => {
    const size = Number(
      getMBsFromBytes(
        uploadedMedia.reduce((acc, media) => acc + media.fileSize, 0)
      )
    );
    setUsedMemorySize(size);
  }, [uploadedMedia]);

  const cleanQRCodeData = useCallback(() => {
    setQRCodeData({});
    setQrCodeDataError("");
  }, []);

  const fetchQRCodeData = useCallback(
    ({ qrCodeId, isMainView = false }) => {
      (async () => {
        try {
          setQrCodeDataError("");
          setIsFetchingQRCodeData(true);
          if (isMainView) {
            const qrCodeBase64Data = await getQrCodeBase64(qrCodeId);
            const qrCodeData = await getQRCodeDataById(
              qrCodeBase64Data.qrCodeDataId
            );
            if (isEmpty(qrCodeData)) {
              throw new Error("wrong qrCodeId");
            }
            setQRCodeData(qrCodeData);
          } else {
            const qrCodes = await getUserQRCodeIds();
            if (qrCodes.includes(qrCodeId)) {
              const qrCodeData = await getQRCodeDataById(qrCodeId);
              if (isEmpty(qrCodeData)) {
                throw new Error("wrong qrCodeId");
              }
              setQRCodeData(qrCodeData);
            } else {
              throw new Error("wrong qrCodeId");
            }
          }
        } catch (error) {
          sendEvent("error_load_qr_code_data");

          toast.error(t("toastMessages.loadQRDataFailed"));
          console.log("error:", error);
          setQrCodeDataError(
            `Error during fetching QRLink data with id: ${qrCodeId}`
          );
        } finally {
          setIsFetchingQRCodeData(false);
        }
      })();
    },
    [sendEvent]
  );

  const value = useMemo(
    () => ({
      fetchQRCodeData,
      qrCodeData,
      qrCodeDataError,
      isFetchingQRCodeData,
      cleanQRCodeData,
      usedMemorySize,
    }),
    [
      fetchQRCodeData,
      qrCodeData,
      qrCodeDataError,
      isFetchingQRCodeData,
      cleanQRCodeData,
      usedMemorySize,
    ]
  );

  return (
    <QRCodeDataContext.Provider value={value}>
      {children}
    </QRCodeDataContext.Provider>
  );
};
