import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Dialog,
  Input,
  Typography,
} from "@material-tailwind/react";
import { validate as emailValidator } from "email-validator";

import useLogin from "../../hooks/useLogin";
import GoogleIcon from "../../svg/GoogleIcon";

const SignIn = ({ isOpened, open, closeMobileNav }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [error, setError] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  useEffect(() => {
    setEmail("");
    setEmailError("");
    setError("");
    setIsNotificationShown(false);
    setIsTermsAccepted(false);
  }, [isOpened]);

  const { signIn, loginWithGoogle, loading } = useLogin();

  const onEmailChange = (e) => {
    setEmailError("");
    setError("");
    setEmail(e.target.value);
  };

  const checkIsFormValid = () => {
    let isValid = true;
    if (!email.trim()) {
      setEmailError("Required");
      isValid = false;
    } else if (!emailValidator(email)) {
      setEmailError("Email is invalid");
      isValid = false;
    }

    return isValid;
  };

  const onSignInClick = () => {
    const isValid = checkIsFormValid();
    if (!isValid) {
      return;
    }

    setError("");
    signIn({
      email: email.trim(),
      setError: setError,
      onSuccess: () => {
        setIsNotificationShown(true);
      },
    });
  };

  return (
    <Dialog
      size="xs"
      open={isOpened}
      handler={open}
      className="bg-transparent shadow-none"
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSignInClick();
        }}
      >
        <Card className="mx-auto w-full max-w-[24rem]">
          <CardHeader
            variant="gradient"
            color="gray"
            className="mb-4 grid h-28 place-items-center"
          >
            <Typography variant="h3" color="white">
              {t("signInDialog.title")}
            </Typography>
          </CardHeader>
          <CardBody className="flex flex-col gap-4">
            {isNotificationShown ? (
              <Alert
                open={isNotificationShown}
                className="bg-[#0d9488] text-white !mr-0"
              >
                <Typography className="leading-5 !-mr-12">
                  {t("signInDialog.linkSentMesasge")}
                </Typography>
              </Alert>
            ) : (
              <>
                {error && (
                  <Typography
                    variant="small"
                    className="mt-1 -mt-6 text-red-700"
                  >
                    {error}
                  </Typography>
                )}

                <div className="mb-6">
                  <Checkbox
                    checked={isTermsAccepted}
                    onChange={(e) => setIsTermsAccepted(e.target.checked)}
                    disabled={loading}
                    containerProps={{
                      className: "-mt-5",
                    }}
                    ripple={false}
                    label={
                      <div>
                        <Typography
                          color="blue-gray"
                          className="inline font-medium"
                        >
                          <Trans
                            i18nKey="signInDialog.agreement"
                            t={t}
                            components={{
                              lnk1: (
                                <Typography
                                  as="a"
                                  href="https://firebasestorage.googleapis.com/v0/b/echo-project-bc60a.appspot.com/o/policies%2FRegulamin%20serwisu%20EchoQr.pdf?alt=media&token=be773f42-5481-4a05-bc1b-ea504b11e7bc"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline font-medium transition-colors underline text-[#0d9488]"
                                />
                              ),
                              lnk2: (
                                <Typography
                                  as="a"
                                  href="https://firebasestorage.googleapis.com/v0/b/echo-project-bc60a.appspot.com/o/policies%2FPolityka%20prywatnos%CC%81ci%20EchoQR.pdf?alt=media&token=7af7f971-f57f-4f15-8c07-af29a01e173c"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline font-medium transition-colors underline text-[#0d9488]"
                                />
                              ),
                            }}
                          />
                        </Typography>
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          {t("signInDialog.acceptToSignIn")}
                        </Typography>
                      </div>
                    }
                  />
                </div>

                <Button
                  variant="outlined"
                  fullWidth
                  loading={loading}
                  disabled={loading || !isTermsAccepted}
                  className="flex justify-center items-center gap-4 mb-5 whitespace-nowrap"
                  onClick={() =>
                    loginWithGoogle({
                      setError,
                      onSuccess: () => {
                        closeMobileNav();
                        open();
                      },
                    })
                  }
                >
                  <GoogleIcon className="w-7 h-7 -my-2" />
                  {t("signInDialog.continueWithGoogle")}
                </Button>

                <div className="-mt-3 mb-1.5 flex justify-center">
                  <Typography text="gray">
                    {t("signInDialog.orWithLink")}
                  </Typography>
                </div>
                <Input
                  label={t("signInDialog.emailFieldTitle")}
                  type="email"
                  id="email"
                  name="email"
                  size="lg"
                  className="!text-base"
                  value={email}
                  onChange={onEmailChange}
                  error={!!emailError}
                  disabled={loading || !isTermsAccepted}
                />
                <Button
                  variant="gradient"
                  fullWidth
                  loading={loading}
                  disabled={loading || !isTermsAccepted}
                  className="justify-center"
                  type="submit"
                >
                  {t("signInDialog.getSignInLinkButtonLabel")}
                </Button>
              </>
            )}
          </CardBody>
        </Card>
      </form>
    </Dialog>
  );
};

export default SignIn;
