import { Typography } from "@material-tailwind/react";

import { useAuth } from "../../context/auth/AuthContext";
import { EDIT_BLOCK_STYLES } from "../../constants";
import { useAnalyticsPage } from "../../hooks/useAnalytics";
import { useTranslation } from "react-i18next";

const AccountDetails = () => {
  const { t } = useTranslation();
  useAnalyticsPage();

  const { currentUser } = useAuth();

  return (
    <div className={`${EDIT_BLOCK_STYLES}`}>
      <div className="flex gap-2 mb-4">
        <Typography color="black" className="text-base">
          {t("accountDetails.email")}
        </Typography>
        <Typography className="text-base" color="gray">
          {currentUser.email}
        </Typography>
      </div>

      <div className="flex gap-2">
        <Typography color="black" className="text-base">
          {t("accountDetails.emailVerified")}
        </Typography>
        <Typography className="text-base" color="gray">
          {currentUser.emailVerified
            ? t("accountDetails.yes")
            : t("accountDetails.no")}
        </Typography>
      </div>
    </div>
  );
};

export default AccountDetails;
