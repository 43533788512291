export const FILE_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  UNKNOWN: "unknown",
};

export const DEFAULT_DRAFT_TEXT_DATA = {
  fullName: "",
  startDate: "",
  endDate: "",
  description: "",
  interestingAnswers: {},
  achievements: [],
};

export const LANGUAGES = {
  PL: "PL",
  EN: "EN",
};

export const EDIT_BLOCK_STYLES =
  "px-4 sm:px-10 py-4 sm:py-10 mb-12 bg-white border border-solid border-[#e3ebf7] rounded-xl w-full z-10";

export const MB_MAX_LIMIT = 1000;

export const DELIVERY_STATUSE_IDS = {
  preparation_for_delivery: "preparation_for_delivery",
  order_has_been_shipped: "order_has_been_shipped",
  // in_transit: "in_transit",
  // ready_for_pickup: "ready_for_pickup",
  // order_picked_up: "order_picked_up",
};

export const DELIVERY_STATUSES = {
  [DELIVERY_STATUSE_IDS.preparation_for_delivery]: "Preparation for delivery",
  [DELIVERY_STATUSE_IDS.order_has_been_shipped]: "Order has been shipped",
  // [DELIVERY_STATUSE_IDS.in_transit]: "In transit",
  // [DELIVERY_STATUSE_IDS.ready_for_pickup]: "Ready for Pickup",
  // [DELIVERY_STATUSE_IDS.order_picked_up]: "Order Picked Up",
};

export const QR_CODES_LIMIT = 5;

export const ADMIN_UIDS = [
  "vKy6p83wv3Ov9s1VupUW9rAQPwo1",
  // "BLBf1PAq4COa2t2sP8AcNjnALvz2",
];

export const LANGUAGE_STORAGE_KEY = "language_echoqr";

export const MAX_TEXT_FIELD_VALUE_LENGTH = 1000;
export const MAX_BIO_FIELD_VALUE_LENGTH = 10000;
