import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Button, Spinner } from "@material-tailwind/react";
import { toast } from "react-toastify";

import QRCodeCard from "./QRCodeCard";
import {
  bindQRCodeIdToUser,
  createNewQR,
  getUserQRCodesLimit,
  getUserQrCodesDataAmount,
  listenUserQrCodes,
} from "../../api/firebase/media";
import PlusIcon from "../../svg/PlusIcon";
import { PATH_NAMES } from "../../routes";
import { useAuth } from "../../context/auth/AuthContext";
import { QR_CODES_LIMIT } from "../../constants";
import { useAnalyticsEvent, useAnalyticsPage } from "../../hooks/useAnalytics";
import { useTranslation } from "react-i18next";

const QRCodes = () => {
  const { t } = useTranslation();
  useAnalyticsPage();
  const { sendEvent } = useAnalyticsEvent();

  const [qrCodes, setQrCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();

  const snapshotUpdateHandler = useCallback((snapshot) => {
    try {
      setIsLoading(true);
      const newQrCodes = [];
      snapshot.forEach((doc) => {
        newQrCodes.push({ id: doc.id, ...doc.data() });
      });
      const sortedQrCodes = newQrCodes.sort(
        (a, b) => b.creationDate - a.creationDate
      );

      setQrCodes(sortedQrCodes);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const snapshotErrorHandler = useCallback((error) => {
    console.log("error:", error);
  }, []);

  useEffect(() => {
    let unsubscribe = null;
    try {
      unsubscribe = listenUserQrCodes({
        snapshotUpdateHandler,
        snapshotErrorHandler,
      });
    } catch (error) {
      sendEvent("error_load_qr_codes");

      toast.error(t("toastMessages.loadQRCodesFailed"));
      console.log("error:", error);
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [snapshotErrorHandler, snapshotUpdateHandler]);

  const navigate = useNavigate();
  const [isQrCodeDataPreparing, setIsQrCodeDataPreparing] = useState(false);

  const [userQRCodesLimit, setUserQRCodesLimit] = useState(QR_CODES_LIMIT);

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    (async () => {
      try {
        const newLimit = await getUserQRCodesLimit();
        setUserQRCodesLimit(newLimit);
      } catch (error) {
        sendEvent("error_load_user_qr_codes_limit");

        toast.error(t("toastMessages.loadQRLimitFailed"));
      }
    })();
  }, [currentUser]);

  const onCreateQrCodeClick = async () => {
    sendEvent("click_create_one_more_qr_link");

    try {
      setIsQrCodeDataPreparing(true);

      const qrCodesDataAmount = await getUserQrCodesDataAmount(currentUser.uid);
      if (qrCodesDataAmount >= userQRCodesLimit) {
        sendEvent("limit_qr_codes_reached");

        toast.info(t("toastMessages.maxQRCodesReached"));
        return;
      }

      const newQRCodeId = uuidv4();
      await createNewQR({ newQRCodeId });
      await bindQRCodeIdToUser({ newQRCodeId });
      navigate(`/${newQRCodeId}${PATH_NAMES.edit}?step=0`);
    } catch (error) {
      sendEvent("error_create_one_more_qr_link");

      toast.error(t("toastMessages.createNewQRCodeFailed"));
      console.log("error:", error);
    } finally {
      setIsQrCodeDataPreparing(false);
    }
  };

  return (
    <div>
      <div className="w-full flex justify-end mb-10">
        <Button
          variant="filled"
          className="flex items-center gap-4"
          disabled={isLoading || isQrCodeDataPreparing}
          loading={isLoading || isQrCodeDataPreparing}
          onClick={onCreateQrCodeClick}
        >
          {t("qrCodeCard.createOneMore")}
          <PlusIcon className="w-4 h-4" />
        </Button>
      </div>

      {(isLoading || isQrCodeDataPreparing) && (
        <div className="flex h-[200px] justify-center items-center">
          <Spinner className="h-12 w-12" />
        </div>
      )}

      {!isLoading && !isQrCodeDataPreparing && qrCodes.length === 0 && (
        <div className="flex h-[200px] justify-center items-center">
          {t("qrCodeCard.noQRCodes")}
        </div>
      )}
      {!isLoading && !isQrCodeDataPreparing && qrCodes.length > 0 && (
        <div className="flex flex-col gap-10 mt-2 w-full">
          {qrCodes.map((data) => (
            <QRCodeCard key={data.id} data={data} />
          ))}
        </div>
      )}
    </div>
  );
};

export default QRCodes;
