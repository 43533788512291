import { useState } from "react";
import { toast } from "react-toastify";
import { getAuth, signInWithPopup, sendSignInLinkToEmail } from "firebase/auth";
import { googleProvider } from "../firebase";
import { isProduction } from "../utils/isProduction";
import { useAnalyticsEvent } from "./useAnalytics";
import { useTranslation } from "react-i18next";

const useLogin = () => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalyticsEvent();

  const auth = getAuth();
  const [loading, setLoading] = useState(false);

  const signIn = async ({ email, setError, onSuccess }) => {
    try {
      sendEvent("click_sign_in_via_email_link");

      setLoading(true);

      const actionCodeSettings = {
        url: isProduction()
          ? "https://echoqr.pl/"
          : "https://echo-project-bc60a.firebaseapp.com/",
        // This must be true.
        handleCodeInApp: true,
      };

      auth.useDeviceLanguage();
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      onSuccess();
    } catch (error) {
      sendEvent("error_sign_in_via_email_link");

      setError(error.code ?? "error");
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const loginWithGoogle = async ({ setError, onSuccess }) => {
    try {
      sendEvent("click_sign_in_via_google");

      setLoading(true);
      const result = await signInWithPopup(auth, googleProvider);

      if (result.user) {
        toast.success(t("toastMessages.signInSuccess"));
        onSuccess();
      }
    } catch (error) {
      sendEvent("error_sign_in_via_google");

      setError(error.code ?? "error");
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    signIn,
    loginWithGoogle,
    loading,
  };
};

export default useLogin;
