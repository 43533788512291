import { useCallback, useEffect, useState } from "react";
import { Spinner, Typography } from "@material-tailwind/react";
import moment from "moment";
import { toast } from "react-toastify";

import BlockDescription from "../../components/BlockDescription/BlockDescription";
import { EDIT_BLOCK_STYLES } from "../../constants";
import { listenUserPayments } from "../../api/firebase/media";
import { useAnalyticsEvent, useAnalyticsPage } from "../../hooks/useAnalytics";
import { useTranslation } from "react-i18next";

const Payments = () => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalyticsEvent();
  useAnalyticsPage();

  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const snapshotUpdateHandler = useCallback((snapshot) => {
    try {
      setIsLoading(true);
      const newPayments = [];
      snapshot.forEach((doc) => {
        newPayments.push({ id: doc.id, ...doc.data() });
      });
      const sortedPayments = newPayments.sort(
        (a, b) => b.paymentTimestamp - a.paymentTimestamp
      );
      setPayments(sortedPayments);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const snapshotErrorHandler = useCallback((error) => {
    console.log("error:", error);
  }, []);

  useEffect(() => {
    let unsubscribe = null;
    try {
      unsubscribe = listenUserPayments({
        snapshotUpdateHandler,
        snapshotErrorHandler,
      });
    } catch (error) {
      sendEvent("error_load_payments");

      toast.error(t("toastMessages.loadPaymentsFailed"));
      console.log("error:", error);
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [snapshotErrorHandler, snapshotUpdateHandler]);

  if (isLoading) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  if (!payments.length) {
    return (
      <div className="flex h-[200px] justify-center items-center">
        {t("payments.noPayments")}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10">
      {payments.map(
        ({ boughtProducts, cost, qrCodeId, paymentUuid, paymentTimestamp }) => (
          <div key={paymentUuid}>
            <BlockDescription
              text={
                paymentTimestamp
                  ? moment(paymentTimestamp).format("YYYY-MM-DD HH:mm:ss")
                  : "-"
              }
              className="text-base"
            />
            <div className={`${EDIT_BLOCK_STYLES} !px-6 !py-4 !mx-0 !my-1`}>
              <div className="flex gap-x-6 gap-y-6 flex-wrap w-full">
                <div>
                  <Typography color="black" className="">
                    {t("payments.boughtProducts")}
                  </Typography>
                  {boughtProducts.map(({ productName, productId }) => (
                    <Typography key={productId} color="gray">
                      {productName}
                    </Typography>
                  ))}
                </div>
                <div>
                  <Typography color="black" className="">
                    {t("payments.paidPrice")}
                  </Typography>
                  <Typography color="gray">{cost.toUpperCase()}</Typography>
                </div>
                <div>
                  <Typography color="black" className="">
                    {t("payments.relatedQRCode")}
                  </Typography>
                  <Typography color="gray">{qrCodeId}</Typography>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Payments;
