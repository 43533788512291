import { useCallback, useEffect, useState } from "react";
import { getQrCodesBase64Amount } from "../api/firebase/media";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useFetchQrCodesAmounts = () => {
  const { t } = useTranslation();

  const [qrCodesFetching, setQrCodesFetching] = useState(false);

  const [freeAluminiumQrCodesAmount, setFreeAluminiumQrCodesAmount] =
    useState(0);
  const [printedAluminiumQrCodesAmount, setPrintedAluminiumQrCodesAmount] =
    useState(0);
  const [usedAluminiumQrCodesAmount, setUsedAluminiumQrCodesAmount] =
    useState(0);

  const [freeAluminiumBlackQrCodesAmount, setFreeAluminiumBlackQrCodesAmount] =
    useState(0);
  const [
    printedAluminiumBlackQrCodesAmount,
    setPrintedAluminiumBlackQrCodesAmount,
  ] = useState(0);
  const [usedAluminiumBlackQrCodesAmount, setUsedAluminiumBlackQrCodesAmount] =
    useState(0);

  const fetchQrCodesAmounts = useCallback(() => {
    (async () => {
      try {
        setQrCodesFetching(true);
        const result = await getQrCodesBase64Amount();
        setFreeAluminiumQrCodesAmount(result.freeAluminiumQrCodesAmount);
        setPrintedAluminiumQrCodesAmount(result.printedAluminiumQrCodesAmount);
        setUsedAluminiumQrCodesAmount(result.usedAluminiumQrCodesAmount);

        setFreeAluminiumBlackQrCodesAmount(
          result.freeAluminiumBlackQrCodesAmount
        );
        setPrintedAluminiumBlackQrCodesAmount(
          result.printedAluminiumBlackQrCodesAmount
        );
        setUsedAluminiumBlackQrCodesAmount(
          result.usedAluminiumBlackQrCodesAmount
        );
      } catch (error) {
        toast.error(t("toastMessages.failedToLoadTheNumberOfAvailableQRCodes"));
        console.log("error:", error);
      } finally {
        setQrCodesFetching(false);
      }
    })();
  }, []);

  useEffect(() => {
    fetchQrCodesAmounts();
  }, [fetchQrCodesAmounts]);

  return {
    qrCodesFetching,
    freeAluminiumQrCodesAmount,
    printedAluminiumQrCodesAmount,
    usedAluminiumQrCodesAmount,
    freeAluminiumBlackQrCodesAmount,
    printedAluminiumBlackQrCodesAmount,
    usedAluminiumBlackQrCodesAmount,
    fetchQrCodesAmounts,
  };
};

export default useFetchQrCodesAmounts;
