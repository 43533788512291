export default {
  successOrder: {
    qrCodeGenerated: "New QR code is generated and new plaque is ordered",
    checkStatus:
      'You can find new QR code on the "QR codes" tab and track the order status of the plaque on the "Orders" tab',
  },
  preview: {
    backButtonLabel: "Back",
    noDataText: "No data",
    biography: "Biography",
    seeBiography: "See the biography",
    achievements: "Achievements",
    copyLinkToShare: "copy link to share",
    unknownFileTypeText: "Unknown file type",
    errorLoadingImage: "Error loading image",
  },
  toastMessages: {
    uploadFileExceedLimit:
      "You are trying to upload a file that will exceed the common limitation of {{limit}} MB.",
    uploadFileFailed: "Failed to upload file.",
    fileUploadSuccess: "File uploaded successfully.",
    attachFileToQRCodeFailed: "Failed to attach file to QR code.",
    loadDeliveriesFailed: "Failed to load orders.",
    updateDeliveryStatusFailed: "Failed to update order status.",
    downloadImageFailed: "Failed to download image.",
    loadPaymentsFailed: "Failed to load payments.",
    loadQRFailed: "Failed to load QR code.",
    qrCodeDeleteSuccess: "QR code deleted successfully.",
    qrCodeDeleteFailed: "Failed to delete QR code.",
    loadQRCodesFailed: "Failed to load QR codes.",
    loadQRLimitFailed: "Failed to load QR code limit.",
    maxQRCodesReached:
      "You have reached the maximum number of QR codes. Contact us if you need more QR codes.",
    createNewQRCodeFailed: "Failed to create new QR code.",
    fileDeleteSuccess: "File deleted successfully.",
    fileDeleteFailed: "Failed to delete file.",
    removeFileFromStorageFailed: "Failed to remove file from storage.",
    descriptionUpdated: "Description updated.",
    descriptionUpdateFailed: "Failed to update description.",
    loadQRDataFailed: "Failed to load QR code data.",
    signInSuccess: "Successfully signed in to your account.",
    signInFailed: "Failed to sign in to your account.",
    incorrectDates: "Incorrect dates. Please correct them before proceeding.",
    valuesForSomeFieldsAreTooLong:
      "Values for some text fields are too long. Please correct them before proceeding.",
    allDataSaved: "All data saved.",
    dataSaveFailed: "Failed to save data.",
    emailNotVerified:
      "Your email is not verified. Contact our support to resolve this issue.",
    checkoutFailed: "Failed to proceed to checkout.",
    fetchPricesFailed: "Failed to fetch prices.",
    urlCopiedSuccess: "URL copied to clipboard successfully.",
    urlCopyFailed: "Failed to copy URL.",
    failedToLoadTheNumberOfAvailableQRCodes:
      "Failed to load the number of available QR codes.",
    failedtoCreateNewAvailableQRCodes:
      "Failed to create new available QR codes.",
  },
  header: {
    admin: "Admin",
    prices: "Prices",
    howItWorks: "How it works",
    account: "Account",
    logOut: "Log Out",
    signIn: "Sign In",
  },
  signInDialog: {
    title: "Sign In",
    linkSentMesasge:
      "An email with the Sign In link has been sent. Please check the email. It usually arrives within a minute.",
    continueWithGoogle: "Continue with google",
    orWithLink: "or with the Sign In link",
    getSignInLinkButtonLabel: "get sign in link",
    emailFieldTitle: "Email",
    agreement:
      "I have read and accept <lnk1>the terms and conditions</lnk1> as well as <lnk2>the privacy policy</lnk2>.",
    acceptToSignIn: "Accept to sign in",
  },
  steps: {
    nextStepButtonLabel: "Next step",
    prevStepButtonLabel: "Previous step",
  },
  accountDetails: {
    email: "Email:",
    emailVerified: "Email verified:",
    no: "no",
    yes: "yes",
  },
  payments: {
    boughtProducts: "Bought products",
    paidPrice: "Paid price",
    relatedQRCode: "Related qr code",
    noPayments: "No payments",
  },
  deliveries: {
    orderCreatedAt: "Order created at",
    product: "Product",
    deliveryInformation: "Recipient's shipping details",
    phone: "phone:",
    relatedQRCode: "Related QR code",
    status: "Status",
    preparation_for_delivery: "Preparation for delivery",
    order_has_been_shipped: "Order has been shipped",
    // in_transit: "In transit",
    // ready_for_pickup: "Ready for pickup",
    // order_picked_up: "Order picked up",
    noDeliviries: "No deliviries",
  },
  qrCodeCard: {
    usedMediaSize: "Used media size",
    textDataExists: "Text data exists",
    no: "no",
    yes: "yes",
    createOneMore: "Create one more QR code",
    noQRCodes: "No QR codes",
    orderStatus: "Order status",
    unfinished: "unfinished",
    editButtonLabel: "Edit",
    orderAnotherPlaque: "Order another plaque",
    continueCreation: "Continue creation QR code",
    downloadQRImage: "Download QR image",
    whereItLeadsTo: "Where it leads to",

    deleteQrCodeDialog: {
      text: "Are you sure you want to delete unfinished order?",
      cancelButtonLabel: "Cancel",
      deleteButtonLabel: "Delete",
    },
  },
  mediaUploadStep: {
    stepTitle: "Collection of memories",
    stepDescription:
      "On this step you can upload any photo and video files with the person.",
    usedSizeText: "{{usedMemorySize}} MB used out of {{maxSize}} MB available",
    uploadFileButtonLabel: "Upload File",
    unknownFileType: "Unknown file type",
    mediaDescriptionFieldLabel: "Description",
    deleteFileButtonLabel: "delete file",
    deleteFileDialog: {
      text: "You want to delete file?",
      cancelButtonLabel: "Cancel",
      deleteButtonLabel: "Delete",
    },
    descriptionTooLong: "Description is too long",
  },
  textStep: {
    stepTitle: "Story",
    stepDescription:
      "This is optional step. On this step you can provide various text information about the person.",
    mainInfoTitle: "Main inforamtion",
    nameFieldLabel: "Full name",
    dateOfBirthFieldLabel: "Date of birth",
    dateOfDeathFieldLabel: "Date of death",
    dateError: "Date of death should follow the date of birth",
    fullNameTooLong: "Full name is too long",
    bioTooLong: "Biography is too long",
    achievementTooLong: "Achievement description is too long",
    answerTooLong: "Answer is too long",
    biographyTitle: "Biographical fragments",
    biographyExampleLabel: "Example",
    biographyFieldLabel: "Biography",
    biographyExample:
      "My mother, Jane Doe, was born on April 5, 1948, in the quaint town of Hillside, a place known for its rolling hills and warm community. From a young age, she displayed a remarkable curiosity and zest for life, qualities that would define her throughout her years. <br /> <br /> Growing up in Hillside, she was an active member of the community, participating in various local events and excelling in her studies. Her passion for learning led her to pursue a degree in education, which she achieved with honors. <br /> <br /> After marrying my father, John, she dedicated her life to teaching and raising a family. We moved several times due to my father's job, living in vibrant cities like Chicago and San Francisco, and later settling in the peaceful suburbs of Maplewood. Each place we lived, my mother made a home filled with love and warmth. <br /> <br /> One of the most interesting points in her life was her volunteer work. She spent countless hours helping at local shelters and organizing community events. Her generosity and kindness were felt by everyone around her. She had a unique ability to connect with people, making lifelong friends wherever she went. <br /> <br /> Despite the many challenges life threw her way, she faced each one with grace and resilience. Her strength was a source of inspiration for all of us. She was deeply loved by her family and friends, and her presence brought joy and comfort to those around her. <br /> <br /> My mother passed away peacefully on June 10, 2023, surrounded by her loved ones. Her legacy of love, kindness, and strength continues to live on in the hearts of all who knew her.",
    achievementsTitle: "Achievements of the person",
    addAchievementButtonLabel: "Add achievement",
    oneMoreAchievementButtonLabel: "One more achievement",
    achievementFieldLabel: "Achievement description",
    deleteAchievementDialog: {
      text: "Are you sure you want to delete achievement?",
      cancelButtonLabel: "Cancel",
      deleteButtonLabel: "Delete achievement",
    },
    interestingQuestionsTitle: "Interesting questions. Tap to expand",
    interestingQuestions: {
      influenceBook: "The book that had the greatest influence on the person",
      shelterPlace:
        "The place the person considered their sanctuary for reflection",
      favoriteMovie: "The movie the person could watch endlessly",
      totemAnimal: "The animal the person considered their totem or symbol",
      biggestHobby: "The biggest passion or hobby in the person's life",
    },
    interestingQuestionsAnswer: "Your answer",
    saveChanges: {
      title: "Don't forget to save provided chages",
      allSaved: "All text changes saved",
      notSaved: "You have unsaved changes",
      revertChangesButtonLabel: "Revert changes",
      saveChangesButtonLabel: "Save changes",
      revertChangesDialog: {
        text: "Are you sure you want to revert changes?",
        cancelButtonLabel: "Cancel",
        revertChangesButtonLabel: "Yes, revert changes",
      },
      unsavedChangesDialog: {
        text: "You have unsaved chages on this step. Save or revert them before proceeding to other steps.",
        cancelButtonLabel: "Cancel",
        revertChangesButtonLabel: "Revert changes",
        saveChangesButtonLabel: "Save changes",
      },
    },
  },
  payStep: {
    stepTitle: "Order",
    stepDescription: "Choose the plaque and proceed to the checkout.",
    choosePlaqueTitle: "Choose the plaque you want to order",
    chosenTadLabel: "Chosen",
    chooseButtonLabel: "Choose",
    deliveryInfoTitle: "Recipient's shipping details (Required)",
    fullNameFieldTitle: "Full name",
    emailFieldTitle: "Email",
    phoneNumberFieldTitle: "Polish phone number",
    parcelLockerText: "In-post parcel locker:",
    selectLockerButtonLabel: "Select parcel locker",
    changeLockerButtonLabel: "Change parcel locker",
    selectLockerDialog: {
      cancelButtonLabel: "Cancel",
    },
    requiredText: "Required",
    tooLongFullName: "Too long full name",
    tooLongEmail: "Too long email",
    tooLongPhoneNumber: "Too long phone number",
    invalidEmail: "Invalid email",
    invalidPhoneNumber: "Invalid phone number",
    summaryTitle: "Summary",
    previewButtonLabel: "Preview",
    checkoutButtonLabel: "Go to checkout",
    checkoutDisabledButtonLabel: "Checkout temporary disabled",
    requiredInfoMissedText: "Required recipient's shipping details is missed",
    deliveryCostText: "Delivery cost:",
    totalText: "Total:",
    plaqueIsOrdered: "A plaque for this QR code has already been ordered.",
    unknownPlaque: "Unknown plaque",
    noAvailableAluminiumPlaques:
      "No aluminum plaques available for now. We are working to fix it. Please try again later.",
    noAvailableAluminiumBlackPlaques:
      "No aluminum (black) plaques available for now. We are working to fix it. Please try again later.",
    promocodeFieldTitle: "Promo Code",
    promocodeApplyButton: "Apply",
    promocodeError: "Not found",
    discountTitle: "Discount",
  },
  footer: {
    contacts: "Contacts",
    helpCenter: "Support",
    policies: "Policies",
    allRightsReserved: "All Rights Reserved",
    termsAndConditions: "Terms And Conditions",
    privacyPolicy: "Privacy Policy",
  },
  // b2bCenterPage: {
  //   title: "We are open for partnership!",
  //   description:
  //     "Describe your offer below and send the message. We will respond to your email as soon as possible.",
  //   companyNameFieldLabel: "Your company name",
  //   offerDescriptionFieldLabel: "Offer description",
  //   agreement: "I agree with the <lnk>terms and conditions</lnk>",
  //   sendButtonLabel: "Send",
  // },
  // helpCenterPage: {
  //   title: "How can we help you?",
  //   description:
  //     "If you have any issues with the service, describe them below and send the message. We will respond to your email as soon as possible.",
  //   issueDescriptionFieldLabel: "Issue description",
  //   agreement: "I agree with the <lnk>terms and conditions</lnk>",
  //   sendButtonLabel: "Send",
  // },
  account: {
    menuItems: {
      qrCodes: "QR codes",
      deliveries: "Orders",
      payments: "Payments",
      accountDetails: "Account details",
    },
  },
  howItWorksPage: {
    title: "Detailed instructions on how Echo QR works",
    plaquesExample: { title: "Example of plaques" },
    createAccount: {
      title: "Create an account",
      description:
        "After logging into your account, you will have the ability to create a QR code, upload photos and videos for that QR code, and order a plaque. So step 0 is to register and log into your account.",
    },
    uploadPhotoAndVideo: {
      title: "Upload photo and video",
      description:
        'By clicking the "Create new QR code" button on the QR codes page or the "Try Now" button on the main page, you will go to the first step of creating a QR code where you can upload photo and video files.',
      description2:
        "One QR code can contain media files with a total size of no more than 1000 MB. If you need more storage, please write to us at support@echoqr.pl and we will consider your situation.",
    },
    provideTextInformation: {
      title: "Provide text information",
      description:
        "In the second step, you can provide text information about the person: name, birth and death dates, biographical moments, achievements, and more.",
    },
    preview: {
      title: "Preview",
      description:
        'At every step of creating the QR code, you can click the "Preview" button to see how the page will look for someone who scans your QR code',
    },
    orderPlaque: {
      title: "Order plaque",
      description:
        "In the third and final step, choose the plaque that suits you best. Provide recipient's shipping details and proceed to payment.",
      description2:
        "The QR code will only be generated after successful payment.",
      description3:
        "After successful payment, you will be directed to the QR codes page where you will find your newly created QR code.",
      // description4:
      //   'For an already created QR code, you can order multiple plaques by simply clicking the "Order another plaque" button or by clicking the "Edit" button and going to the final step.',
      description5:
        "On the Orders page, you can track the status of your order.",
      description6:
        "All plaques are delivered via InPost. InPost S.A. is a Polish public logistics company with limited liability, offering courier services, parcel delivery, and express mail.",
    },
    stopContinueCreationProcess: {
      title: "Pause/Continue creation process",
      description:
        'Once you start creating a QR code, you can continue the creation process later. To continue creating the QR code, find it on the QR links page. Unfinished (unpaid) QR codes have an order status of unfinished. To continue creating the QR code, simply click the "Continue creation QR code" button.',
      description2:
        "If the QR code has an order status of unfinished (i.e., it is unpaid and the plaque is not ordered) and you have not made any changes to it for 14 days, it will be automatically deleted.",
    },
    continueEditing: {
      title: "Continue editing",
      description:
        'Even after the QR code is paid for and the plaque is ordered, you can still change the data linked to it, upload new photo and video files, edit text information, and order new plaques. To do this, simply find your QR code on the QR links page and click the "Edit" button.',
    },
    qrCodesLimitation: {
      title: "QR codes limitation",
      description:
        "One account can create a maximum of 5 QR codes. Write to us at support@echoqr.pl if you need to extend this limit and we will consider your request.",
    },
    qrCodeLifetime: {
      title: "QR code lifetime",
      description:
        "The photos and videos uploaded to the QR code will be stored for 10 years after the plaque is ordered. After this period, we will send a notification to your email with instructions on how to extend the storage of the media",
    },
  },
  prices: {
    title: "The cost of plaques and what is included in this price",
    features: {
      one: "The ability to upload images and videos with a total size of 1000 MB for this QR code",
      two: "Data storage and access to it for 10 years (with the possibility of extension)",
      three:
        "The ability to edit the memory page at any time (uploading/deleting media, editing information)",
      four: "The ability to see who the person was just by coming to their memorial and scanning the code",
      five: "The ability to share with others who the deceased person was during their lifetime",
    },
  },
  mainPage: {
    gallery: "Choose your option",
    pageExample: {
      title: "Create a page to always remember your loved one",
      buttonTitle: "See the full page",
    },
    title1: "A tombstone is not just a stone,",
    title2: "it's an entire life!",
    tryNow: "Try now",
    simpleSteps: "Simple 3 steps",
    steps: {
      one: {
        title: "Step 1",
        subTitle: "Preserve Memories in Photos and Videos",
        description:
          "Photos and videos best preserve memories of a person. <underline>Upload any photos and videos that remind you of who they were:</underline> their laughter, dancing, singing. Don’t let yourself forget them.",
      },
      two: {
        title: "Step 2",
        subTitle: "Describe the Personality",
        description:
          "<underline>Add a short biography of the person.</underline> Talk about their achievements, interests, and favorite books. Let others learn what inspired your loved one.",
      },
      three: {
        title: "Step 3",
        subTitle: "Plaque and Delivery",
        description:
          "<underline>Choose a plaque</underline> on which we will engrave the QR code, and provide the recipient's shipping details. We will ensure the plaque is delivered to you as quickly as possible.",
      },
    },
    whatSetsUsApart: {
      title: "What sets us apart?",
      one: {
        title: "PREVIEW",
        subTitle: "Preview during QR code creation",
        description:
          'At each stage, <underline>you can see</underline> how the page linked to the QR code will look. Simply click the "Preview" button.',
      },
      two: {
        title: "LIFETIME ACCESS",
        subTitle: "Lifetime access to editing",
        description:
          "After creating the QR code and receiving the plaque, <underline>you will always be able to edit the information.</underline> Add or remove photos and videos, edit texts.",
      },
      three: {
        title: "SECURITY",
        subTitle: "Data protection",
        description:
          "Only you will have access to edit the data related to the QR code. <underline>Your data is securely protected.</underline>",
      },
      four: {
        title: "PLAQUE",
        subTitle: "Minimalism and aesthetics",
        description:
          "The small plaque with the QR code <underline>looks elegant on monuments made of any material.</underline> It is made of durable materials, resistant to weather conditions and the passage of time. The plaque measures 60x80 mm. It is easy to attach.",
      },
      five: {
        title: "Media files",
        subTitle: "The ability to upload movies and photos",
        description:
          "Echo QR provides <underline>the ability to upload photo and video files</underline> to see and remember what a person was like in action during their lifetime. No text can convey the emotions and memories that photos and videos hold.",
      },
    },
    clientsAmount: "Over 700 clients have already preserved their memories.",
  },
};
