import React, { Component } from "react";
import BrokenGraveIcon from "../../svg/BrokenGraveIcon";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    console.log("error", error);
    console.log("errorInfo", errorInfo);
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  handleRefreshClick = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <div className="bg-[#f5f9fc] flex flex-col items-center content-height-without-header">
          <div className="pt-10 pb-10 sm:pb-20 content-width flex flex-col items-center gap-10">
            <BrokenGraveIcon className="w-24 h-24" />
            <span>
              Coś poszło nie tak. Proszę spróbować{" "}
              <span
                onClick={this.handleRefreshClick}
                className="underline cursor-pointer text-[#0d9488]"
              >
                odświeżyć stronę.
              </span>
            </span>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
