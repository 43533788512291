export default {
  successOrder: {
    qrCodeGenerated:
      "Nowy kod QR został wygenerowany i zamówiono nową tabliczkę",
    checkStatus:
      'Nowy kod QR możesz znaleźć na karcie "Kody QR", a status zamówienia tabliczki możesz śledzić na karcie "Zamówienia"',
  },
  preview: {
    backButtonLabel: "Wstecz",
    noDataText: "Brak danych",
    biography: "Biografia",
    seeBiography: "Zobacz biografię",
    achievements: "Osiągnięcia",
    copyLinkToShare: "skopiuj link do udostępnienia",
    unknownFileTypeText: "Nieznany typ pliku",
    errorLoadingImage: "Błąd ładowania obrazu",
  },
  toastMessages: {
    uploadFileExceedLimit:
      "Próbujesz przesłać plik, który przekroczy ogólny limit {{limit}} MB.",
    uploadFileFailed: "Nie udało się załadować pliku.",
    fileUploadSuccess: "Plik został pomyślnie załadowany.",
    attachFileToQRCodeFailed: "Nie udało się dołączyć pliku do kodu QR.",
    loadDeliveriesFailed: "Nie udało się załadować dane. Spróbuj jeszcze raz.",
    updateDeliveryStatusFailed:
      "Nie udało się zaktualizować statusu zamówienia.",
    downloadImageFailed: "Nie udało się pobrać obrazu.",
    loadPaymentsFailed: " Nie udało się załadować dane. Spróbuj jeszcze raz.",
    loadQRFailed: "Nie udało się załadować kodu QR.",
    qrCodeDeleteSuccess: "Kod QR został pomyślnie usunięty.",
    qrCodeDeleteFailed: "Nie udało się usunąć kodu QR.",
    loadQRCodesFailed: "Nie udało się załadować kodów QR.",
    loadQRLimitFailed: "Nie udało się załadować limitu kodów QR.",
    maxQRCodesReached:
      "Osiągnąłeś maksymalną liczbę kodów QR. Skontaktuj się z nami, jeśli potrzebujesz więcej kodów QR.",
    createNewQRCodeFailed: "Nie udało się utworzyć nowego kodu QR.",
    fileDeleteSuccess: "Plik został pomyślnie usunięty.",
    fileDeleteFailed: "Nie udało się usunąć pliku.",
    removeFileFromStorageFailed: "Nie udało się usunąć pliku z magazynu.",
    descriptionUpdated: "Opis został zaktualizowany.",
    descriptionUpdateFailed: "Nie udało się zaktualizować opisu.",
    loadQRDataFailed: "Nie udało się załadować danych kodu QR.",
    signInSuccess: "Pomyślnie zalogowano do konta.",
    signInFailed: "Nie udało się zalogować do konta.",
    incorrectDates: "Nieprawidłowe daty. Popraw je przed kontynuacją.",
    valuesForSomeFieldsAreTooLong:
      "Wartości niektórych pól tekstowych są za długie. Popraw je przed kontynuowaniem.",
    allDataSaved: "Wszystkie dane zostały zapisane.",
    dataSaveFailed: "Nie udało się zapisać danych.",
    emailNotVerified:
      "Twój email nie został zweryfikowany. Skontaktuj się z naszym wsparciem technicznym, aby rozwiązać ten problem.",
    checkoutFailed: "Nie udało się przejść do kasy.",
    fetchPricesFailed: "Nie udało się pobrać cen.",
    urlCopiedSuccess: "URL został pomyślnie skopiowany do schowka.",
    urlCopyFailed: "Nie udało się skopiować URL.",
    failedToLoadTheNumberOfAvailableQRCodes:
      "Nie udało się załadować ilości wolnych kodów QR.",
    failedtoCreateNewAvailableQRCodes:
      "Nie udało się utworzyć nowych wolnych kodów QR.",
  },
  header: {
    admin: "Admin",
    prices: "Ceny",
    howItWorks: "Jak to działa",
    account: "Konto",
    logOut: "Wyloguj się",
    signIn: "Zaloguj się",
  },
  signInDialog: {
    title: "Zaloguj się",
    linkSentMesasge:
      "Email z linkiem do logowania został wysłany. Proszę sprawdzić email. Zwykle przychodzi w ciągu minuty.",
    continueWithGoogle: "Kontynuuj przez Google",
    orWithLink: "lub za pomocą linku do logowania",
    getSignInLinkButtonLabel: "uzyskaj link do logowania",
    emailFieldTitle: "Email",
    agreement:
      "Zapoznałem się i akceptuję <lnk1>regulamin</lnk1> oraz <lnk2>politykę prywatnośc</lnk2>.",
    acceptToSignIn: "Zaakceptuj, aby zalogować się",
  },
  steps: {
    nextStepButtonLabel: "Następny krok",
    prevStepButtonLabel: "Poprzedni krok",
  },
  accountDetails: {
    email: "Email:",
    emailVerified: "Email zweryfikowany:",
    no: "nie",
    yes: "tak",
  },
  payments: {
    boughtProducts: "Zakupione produkty",
    paidPrice: "Zapłacona cena",
    relatedQRCode: "Powiązany kod QR",
    noPayments: "Brak płatności",
  },
  deliveries: {
    orderCreatedAt: "Zamówienie utworzone",
    product: "Produkt",
    deliveryInformation: "Dane odbiorcy przesyłki",
    phone: "telefon:",
    relatedQRCode: "Powiązany kod QR",
    status: "Status",
    preparation_for_delivery: "Przygotowanie do wysyłki",
    order_has_been_shipped: "Zamówienie zostało wysłane",
    // in_transit: "W drodze",
    // ready_for_pickup: "Gotowe do odbioru",
    // order_picked_up: "Zamówienie odebrane",
    noDeliveries: "Brak zamówień",
  },
  qrCodeCard: {
    usedMediaSize: "Użyty rozmiar multimediów",
    textDataExists: "Dane tekstowe istnieją",
    no: "nie",
    yes: "tak",
    createOneMore: "Utwórz kolejny kod QR",
    noQRCodes: "Brak kodów QR",
    orderStatus: "Status zamówienia",
    unfinished: "niedokończone",
    editButtonLabel: "Edytuj",
    orderAnotherPlaque: "Zamów kolejną tabliczkę",
    continueCreation: "Kontynuuj tworzenie kodu QR",
    downloadQRImage: "Pobierz obraz QR",
    whereItLeadsTo: "Dokąd prowadzi",
    deleteQrCodeDialog: {
      text: "Czy na pewno chcesz usunąć niedokończone zamówienie?",
      cancelButtonLabel: "Anuluj",
      deleteButtonLabel: "Usuń",
    },
  },
  mediaUploadStep: {
    stepTitle: "Kolekcja wspomnień",
    stepDescription:
      "Na tym etapie możesz przesłać dowolne zdjęcia i filmy z osobą.",
    usedSizeText:
      "{{usedMemorySize}} MB wykorzystane z {{maxSize}} MB dostępnych",
    uploadFileButtonLabel: "Prześlij plik",
    unknownFileType: "Nieznany typ pliku",
    mediaDescriptionFieldLabel: "Opis",
    deleteFileButtonLabel: "Usuń plik",
    deleteFileDialog: {
      text: "Czy chcesz usunąć plik?",
      cancelButtonLabel: "Anuluj",
      deleteButtonLabel: "Usuń",
    },
    descriptionTooLong: "Opis jest za długi",
  },
  textStep: {
    stepTitle: "Biografia",
    stepDescription:
      "Na tym etapie masz możliwość zamieszczenia biografii osoby. Możesz opisać jej doświadczenie zawodowe, drogę życiową oraz wszelkie inne informacje, które uznasz za istotne.",
    mainInfoTitle: "Główne informacje",
    nameFieldLabel: "Imię i nazwisko",
    dateOfBirthFieldLabel: "Data urodzenia",
    dateOfDeathFieldLabel: "Data śmierci",
    dateError: "Sprawdź poprawność podanych dat",
    fullNameTooLong: "Imię i nazwisko jest za długie",
    bioTooLong: "Biografia jest za długa",
    achievementTooLong: "Opis osiągnięcia jest za długi",
    answerTooLong: "Odpowiedź jest za długa",
    biographyTitle: "Fragmenty biograficzne",
    biographyExampleLabel: "Przykład",
    biographyFieldLabel: "Biografia",
    biographyExample:
      "Moja matka, Janina Kowalska, urodziła się 5 kwietnia 1948 roku w urokliwym miasteczku Zbiegów, znanym z malowniczych wzgórz i ciepłej społeczności. Już od najmłodszych lat wykazywała niezwykłą ciekawość i zapał do życia, cechy, które definiowały ją przez całe życie. <br /> <br /> Dorastając w Janowie, była aktywnym członkiem społeczności, uczestnicząc w różnych lokalnych wydarzeniach i odnosząc sukcesy w nauce. Jej pasja do nauki skłoniła ją do podjęcia studiów pedagogicznych, które ukończyła z wyróżnieniem. <br /> <br /> Po ślubie z moim ojcem, Johnem, poświęciła swoje życie nauczaniu i wychowywaniu rodziny. Przeprowadzaliśmy się kilka razy ze względu na pracę ojca, mieszkając w tętniących życiem miastach, takich jak Warszawa i Kraków, a później osiedlając się na spokojnych przedmieściach Wrocaławia. W każdym miejscu, w którym mieszkaliśmy, mama tworzyła dom pełen miłości i ciepła. <br /> <br /> Jednym z najbardziej interesujących momentów w jej życiu była jej praca wolontariacka. Spędzała niezliczone godziny, pomagając w lokalnych schroniskach i organizując wydarzenia społecznościowe. Jej hojność i życzliwość były odczuwalne przez wszystkich wokół. Miała unikalną zdolność do nawiązywania kontaktów z ludźmi, zyskując przyjaciół na całe życie. <br /> <br /> Pomimo wielu wyzwań, jakie stawiało przed nią życie, stawiała im czoła z gracją i odpornością. Jej siła była źródłem inspiracji dla nas wszystkich. Była głęboko kochana przez swoją rodzinę i przyjaciół, a jej obecność przynosiła radość i pocieszenie wszystkim wokół. <br /> <br /> Moja matka zmarła spokojnie 10 czerwca 2023 roku, otoczona przez swoich bliskich. Jej dziedzictwo miłości, życzliwości i siły nadal żyje w sercach wszystkich, którzy ją znali.",
    achievementsTitle: "Osiągnięcia osoby",
    addAchievementButtonLabel: "Dodaj osiągnięcie",
    oneMoreAchievementButtonLabel: "Dodaj kolejne",
    achievementFieldLabel: "Opis osiągnięcia",
    deleteAchievementDialog: {
      text: "Czy na pewno chcesz usunąć osiągnięcie?",
      cancelButtonLabel: "Anuluj",
      deleteButtonLabel: "Usuń",
    },
    interestingQuestionsTitle: "Interesujące pytania. Dotknij, aby rozwinąć",
    interestingQuestions: {
      influenceBook: "Książka, która miała największy wpływ na tę osobę",
      shelterPlace:
        "Miejsce, które ta osoba traktowała jako swoje schronienie do refleksji",
      favoriteMovie: "Film, który ta osoba mogła oglądać bez końca",
      totemAnimal: "Zwierzę, które ta osoba uważała za swój totem lub symbol",
      biggestHobby: "Największa pasja lub hobby w życiu tej osoby",
    },
    interestingQuestionsAnswer: "Twoja odpowiedź",
    saveChanges: {
      title: "Nie zapomnij zapisać wprowadzonych zmian",
      allSaved: "Wszystkie zmiany zostały zapisane",
      notSaved: "Masz niezapisane zmiany",
      revertChangesButtonLabel: "Cofnij zmiany",
      saveChangesButtonLabel: "Zapisz zmiany",
      revertChangesDialog: {
        text: "Czy na pewno chcesz cofnąć zmiany?",
        cancelButtonLabel: "Anuluj",
        revertChangesButtonLabel: "Tak",
      },
      unsavedChangesDialog: {
        text: "Masz niezapisane zmiany na tym etapie. Zapisz lub cofnij je przed kontynuacją.",
        cancelButtonLabel: "Anuluj",
        revertChangesButtonLabel: "Cofnij zmiany",
        saveChangesButtonLabel: "Zapisz zmiany",
      },
    },
  },
  payStep: {
    stepTitle: "Zamówienie",
    stepDescription: "Wybierz tabliczkę QR i przejdź do kasy.",
    choosePlaqueTitle: "Wybierz tabliczkę QR, którą chcesz zamówić",
    chosenTadLabel: "Wybrano",
    chooseButtonLabel: "Wybierz",
    deliveryInfoTitle: "Dane odbiorcy przesyłki (wymagane)",
    fullNameFieldTitle: "Pełne imię i nazwisko",
    emailFieldTitle: "Email",
    phoneNumberFieldTitle: "Polski numer telefonu",
    parcelLockerText: "Paczkomat InPost:",
    selectLockerButtonLabel: "Wybierz paczkomat",
    changeLockerButtonLabel: "Zmień paczkomat",
    selectLockerDialog: {
      cancelButtonLabel: "Anuluj",
    },
    requiredText: "Wymagane",
    tooLongFullName: "Zbyt długie imię i nazwisko",
    tooLongEmail: "Zbyt długi email",
    tooLongPhoneNumber: "Zbyt długi numer telefonu",
    invalidEmail: "Nieprawidłowy email",
    invalidPhoneNumber: "Nieprawidłowy numer telefonu",
    summaryTitle: "Podsumowanie",
    previewButtonLabel: "Podgląd",
    checkoutButtonLabel: "Przejdź do kasy",
    checkoutDisabledButtonLabel: "Kasa tymczasowo wyłączona",
    requiredInfoMissedText: "Brak wymaganych danych odbiorcy przesyłki",
    deliveryCostText: "Koszt dostawy:",
    totalText: "Razem:",
    plaqueIsOrdered: "Tabliczka dla tego kodu QR została już zamówiona.",
    unknownPlaque: "Nieznana tabliczka",
    noAvailableAluminiumPlaques:
      "Obecnie brak dostępnych tabliczek aluminiowych. Pracujemy nad rozwiązaniem problemu. Proszę spróbować później.",
    noAvailableAluminiumBlackPlaques:
      "Obecnie brak dostępnych czarnych tabliczek aluminiowych. Pracujemy nad rozwiązaniem problemu. Proszę spróbować później.",
    promocodeFieldTitle: "Promokod",
    promocodeApplyButton: "Zastosuj",
    promocodeError: "Nie znaleziony",
    discountTitle: "Zniżka",
  },
  footer: {
    contacts: "Kontakty",
    helpCenter: "Wsparcie",
    policies: "Polityki",
    allRightsReserved: "Wszelkie prawa zastrzeżone",
    termsAndConditions: "Regulamin",
    privacyPolicy: "Polityka prywatności",
  },
  // b2bCenterPage: {
  //   title: "Jesteśmy otwarci na współpracę!",
  //   description:
  //     "Opisz swoją ofertę poniżej i wyślij wiadomość. Odpowiemy na Twój e-mail tak szybko, jak to możliwe.",
  //   companyNameFieldLabel: "Nazwa Twojej firmy",
  //   offerDescriptionFieldLabel: "Opis oferty",
  //   agreement: "Zgadzam się z <lnk>regulaminem</lnk>",
  //   sendButtonLabel: "Wyślij",
  // },
  // helpCenterPage: {
  //   title: "Jak możemy Ci pomóc?",
  //   description:
  //     "Jeśli masz jakiekolwiek problemy z usługą, opisz je poniżej i wyślij wiadomość. Odpowiemy na Twój e-mail tak szybko, jak to możliwe.",
  //   issueDescriptionFieldLabel: "Opis problemu",
  //   agreement: "Zgadzam się z <lnk>regulaminem</lnk>",
  //   sendButtonLabel: "Wyślij",
  // },
  account: {
    menuItems: {
      qrCodes: "Kody QR",
      deliveries: "Zamówienia",
      payments: "Płatności",
      accountDetails: "Szczegóły konta",
    },
  },
  howItWorksPage: {
    title: "Szczegółowa instrukcja, jak działa Echo QR",
    plaquesExample: { title: "Przykład tabliczek" },
    createAccount: {
      title: "Stwórz konto",
      description:
        "Po zalogowaniu się na swoje konto będziesz mieć możliwość stworzenia kodu QR, przesyłania zdjęć i filmów do tego kodu QR oraz zamówienia tabliczki. Tak więc krok podstawowy to zarejestrowanie się i zalogowanie na swoje konto.",
    },
    uploadPhotoAndVideo: {
      title: "Przesyłanie zdjęć i filmów",
      description:
        'Po kliknięciu przycisku "Utwórz kolejny kod QR" na stronie "Kody QR" lub "Spróbuj teraz" na stronie głównej, przejdziesz do pierwszego kroku tworzenia kodu QR, na którym możesz przesłać pliki zdjęć i filmów.',
      description2:
        "Jeden kod QR może zawierać pliki multimedialne o łącznej wielkości nieprzekraczającej 1000 MB. Jeśli potrzebujesz więcej pamięci, napisz do nas na support@echoqr.pl, a rozważymy Twoją sytuację.",
    },
    provideTextInformation: {
      title: "Opis życiorysu i wspomnień o osobie",
      description:
        "Na drugim etapie możesz uzupełnić informacje o osobie: imię i nazwisko, najważniejsze momenty z biografii, osiągnięcia. Daj możliwość sobie i innym przypomnieć najpiękniejsze momenty z życia Twojej bliskiej osoby",
    },
    preview: {
      title: "Podgląd",
      description:
        'Na każdym etapie tworzenia kodu QR możesz kliknąć przycisk "Podgląd" aby zobaczyć, jak będzie wyglądała strona dla osób odwiedzających po zeskanowaniu kodu QR.',
    },
    orderPlaque: {
      title: "Zamówienie tabliczki QR",
      description:
        "Na trzecim i ostatnim etapie wybierz tabliczkę, która Ci odpowiada. Podaj dane odbiorcy przesyłki i przejdź do płatności.",
      description2:
        "Sam kod QR zostanie wygenerowany tylko po pomyślnej płatności.",
      description3:
        'Po opłaceniu nowo utworzony kod QR zostanie dodany do Twojego konta na stronę "Kody QR".',
      // description4:
      //   'Dla już utworzonego kodu QR możesz zamówić kilka tabliczek, po prostu klikając przycisk "Zamów kolejną tabliczkę". Możesz również kliknąć przycisk "Edytuj" i przejść do ostatniego kroku.',
      description5:
        'Na stronie "Zamówienia" możesz śledzić status swojego zamówienia.',
      description6:
        "Wszystkie tabliczki są dostarczane przez InPost. InPost S.A. to polska publiczna firma logistyczna z ograniczoną odpowiedzialnością, oferująca usługi kurierskie, dostarczanie paczek i ekspresową pocztę.",
    },
    stopContinueCreationProcess: {
      title: "Pauza/Kontynuowanie procesu tworzenia",
      description:
        'Rozpoczynając tworzenie kodu QR, możesz kontynuować jego tworzenie później. Aby kontynuować tworzenie kodu QR, znajdź go na stronie kodów QR. Niezakończone (nieopłacone) kody QR mają status zamówienia „niedokończone”. Aby kontynuować tworzenie kodu QR, po prostu kliknij przycisk "Continue creation QR code".',
      description2:
        'Jeśli kod QR ma status zamówienia "niedokończone" (czyli nie jest opłacony i tabliczka nie jest zamówiona) i nie dokonywałeś w nim żadnych zmian przez 14 dni, zostanie on automatycznie usunięty.',
    },
    continueEditing: {
      title: "Dokonywanie zmian",
      description:
        'Nawet po opłaceniu kodu QR i zamówieniu tabliczki, możesz zmieniać dane powiązane z kodem, przesyłać nowe zdjęcia i filmy, zmieniać informacje tekstowe i zamawiać nowe tabliczki. Aby to zrobić, po prostu znajdź swój kod QR na stronie Kody QR i kliknij przycisk "Edytuj".',
    },
    qrCodesLimitation: {
      title: "Limit kodów QR",
      description:
        "Na jednym koncie można stworzyć maksymalnie 5 kodów QR. Napisz do nas na support@echoqr.pl, jeśli potrzebujesz zwiększyć ten limit, a my rozważymy ten wniosek.",
    },
    qrCodeLifetime: {
      title: "Czas życia kodu QR",
      description:
        "Przesłane do kodu QR zdjęcia i filmy będą przechowywane przez 10 lat od zamówienia tabliczki. Po upływie tego czasu wyślemy powiadomienie na Twój adres e-mail z instrukcjami, jak przedłużyć przechowywanie mediów.",
    },
  },
  prices: {
    title: "Koszt tabliczek i co wchodzi w tę cenę",
    features: {
      one: "Możliwość przesyłania obrazów i wideo o łącznej wielkości 1000 MB dla tego kodu QR",
      two: "Przechowywanie danych i dostęp do nich przez 10 lat (z możliwością przedłużenia)",
      three:
        "Możliwość edytowania strony pamięci w dowolnym momencie (przesyłanie/usuwanie mediów, edytowanie informacji)",
      four: "Możliwość zobaczenia, kim była osoba, po prostu przychodząc do jej miejsca pamięci i skanując kod",
      five: "Możliwość dzielenia się z innymi, kim była zmarła osoba za życia",
    },
  },
  mainPage: {
    gallery: "Wybierz swoją opcję",
    pageExample: {
      title: "Stwórz stronę by zawsze pamiętać o swoim bliskim",
      buttonTitle: "Zobacz pełną stronę",
    },
    title1: "Nagrobek to nie tylko kamień,",
    title2: "to całe życie!",
    tryNow: "Spróbuj teraz",
    simpleSteps: "Proste 3 kroki",
    steps: {
      one: {
        title: "Krok 1",
        subTitle: "Zachowaj pamięć w postaci plików zdjęciowych i wideo",
        description:
          "Zdjęcia i wideo najlepiej utrwalają wspomnienia o człowieku. <underline>Wgraj dowolne zdjęcia i filmy, które przypominają Ci, jaki był:</underline> jego śmiech, taniec, śpiew. Nie pozwól sobie go zapomnieć.",
      },
      two: {
        title: "Krok 2",
        subTitle: "Opisz osobowość",
        description:
          "<underline>Dodaj historię życia osoby i najpiękniejsze wspomnienia.</underline> Opowiedz o jej osiągnięciach, zainteresowaniach i ulubionych książkach. Pozwól innym dowiedzieć się, co inspirowało Twojego bliskiego.",
      },
      three: {
        title: "Krok 3",
        subTitle: "Tabliczka i dostawa",
        description:
          "<underline>Wybierz tabliczkę,</underline> na którą naniesiemy kod QR, i podaj dane odbiorcy przesyłki. Zadbamy o to, aby tabliczka została dostarczona do Ciebie w jak najkrótszym czasie.",
      },
    },
    whatSetsUsApart: {
      title: "Czym się wyróżniamy?",
      one: {
        title: "PODGLĄD",
        subTitle: "Podgląd podczas tworzenia kodu QR",
        description:
          'Na każdym etapie <underline>możesz zobaczyć, jak będzie wyglądać strona</underline>, do której prowadzi kod QR. Po prostu kliknij przycisk "Podgląd".',
      },
      two: {
        title: "DOSTĘP NA CAŁE ŻYCIE",
        subTitle: "Dostęp na całe życie do edytowania",
        description:
          "Po utworzeniu kodu QR i otrzymaniu tabliczki <underline>zawsze będziesz mógł edytować informacje.</underline> Dodawaj lub usuwaj zdjęcia i filmy, edytuj teksty.",
      },
      three: {
        title: "BEZPIECZEŃSTWO",
        subTitle: "Dane pod ochroną",
        description:
          "Tylko Ty będziesz mieć dostęp do edytowania danych związanych z kodem QR. <underline>Twoje dane są bezpiecznie chronione.</underline>",
      },
      four: {
        title: "TABLICZKA",
        subTitle: "Minimalizm i estetyka",
        description:
          "Mała tabliczka z kodem QR <underline>elegancko wygląda na pomnikach z dowolnego materiału.</underline> Jest wykonana z trwałych materiałów, odpornych na warunki atmosferyczne i upływ czasu. Rozmiar tabliczki to 60x80 mm. Łatwo się ją przymocowuje.",
      },
      five: {
        title: "Media pliki",
        subTitle: "Możliwość przesyłania filmów i zdjęć",
        description:
          "Echo QR daje <underline>możliwość przesyłania zdjęć i plików wideo,</underline> aby zobaczyć i przypomnieć sobie, jaki człowiek był za życia w akcji. Żaden tekst nie odda emocji i wspomnień, które przechowują zdjęcia i filmy.",
      },
    },
    clientsAmount: "Ponad 700 klientów już zachowało swoje wspomnienia",
  },
};
