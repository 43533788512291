import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA8LVwiTcEFEgviqrAbbKbEMVc2dGAH-rc",
  authDomain: "echo-project-bc60a.firebaseapp.com",
  projectId: "echo-project-bc60a",
  storageBucket: "echo-project-bc60a.appspot.com",
  messagingSenderId: "444435856675",
  appId: "1:444435856675:web:1d2230ce7d6d466b43e096",
  measurementId: "G-3HH0XP0ZXM",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);

export const googleProvider = new GoogleAuthProvider();
