import { createContext, useCallback, useMemo, useState } from "react";

export const LoginDialogContext = createContext();

export const LoginDialogProvider = ({ children }) => {
  const [isSignInOpened, setIsSignInOpened] = useState(false);

  const openSignInDialog = useCallback(() => {
    setIsSignInOpened(true);
  }, []);

  const closeSignInDialog = useCallback(() => {
    setIsSignInOpened(false);
  }, []);

  const value = useMemo(
    () => ({
      isSignInOpened,
      openSignInDialog,
      closeSignInDialog,
    }),
    [isSignInOpened, openSignInDialog, closeSignInDialog]
  );

  return (
    <LoginDialogContext.Provider value={value}>
      {children}
    </LoginDialogContext.Provider>
  );
};
