import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/auth/AuthContext";
import { PATH_NAMES } from "../../routes";

const ProtectedRoute = ({ children, pathToRedirect }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return children;
  }

  return <Navigate to={pathToRedirect ?? PATH_NAMES.home} />;
};

export default ProtectedRoute;
