import { Typography } from "@material-tailwind/react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import TiktokIcon from "../../svg/TiktokIcon";
import InstagramIcon from "../../svg/InstagramIcon";

const currentYear = new Date().getFullYear();

const Footer = ({ isShort = false }) => {
  const { t } = useTranslation();

  return (
    <footer className="relative flex w-full justify-center">
      <div className="content-width mt-10">
        <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-2">
          <div className="flex">
            <NavLink exact="true" to="/">
              <img
                src="/echo_qr_logo_2.png"
                className="w-[122px] md:w-[158px]"
                alt="Echo QR logo. Serwis umożliwiający stworzenie tabliczki z kodem QR, którą można umieścić na pomniku lub nagrobku"
              />
            </NavLink>
          </div>

          {!isShort && (
            <div className="grid grid-cols-2 justify-between gap-4">
              <ul>
                <Typography className="font-medium text-gray-500 mb-3">
                  {t("footer.contacts")}
                </Typography>
                <li className="mb-3 text-base text-gray-700">
                  <Typography className="font-medium text-gray-900 mb">
                    {t("footer.helpCenter")}
                  </Typography>
                  <Typography className="font-medium text-[#0d9488]">
                    support@echoqr.pl
                  </Typography>
                </li>
                <li className="text-base text-gray-700">
                  <Typography className="font-medium text-gray-900">
                    B2B
                  </Typography>
                  <Typography className="font-medium text-[#0d9488] mb-3">
                    b2b@echoqr.pl
                  </Typography>
                </li>
              </ul>
              <ul>
                <Typography className="font-medium text-gray-500 mb-3">
                  {t("footer.policies")}
                </Typography>
                <li className="mb-3 text-base text-gray-700 flex">
                  <Typography
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://firebasestorage.googleapis.com/v0/b/echo-project-bc60a.appspot.com/o/policies%2FRegulamin%20serwisu%20EchoQr.pdf?alt=media&token=be773f42-5481-4a05-bc1b-ea504b11e7bc"
                    className="font-medium text-gray-500 underline hover:cursor-pointer text-[#0d9488]"
                  >
                    {t("footer.termsAndConditions")}
                  </Typography>
                </li>
                <li className="mb-3 text-base text-gray-700 flex">
                  <Typography
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://firebasestorage.googleapis.com/v0/b/echo-project-bc60a.appspot.com/o/policies%2FPolityka%20prywatnos%CC%81ci%20EchoQR.pdf?alt=media&token=7af7f971-f57f-4f15-8c07-af29a01e173c"
                    className="font-medium text-gray-500 underline hover:cursor-pointer text-[#0d9488]"
                  >
                    {t("footer.privacyPolicy")}
                  </Typography>
                </li>
                {/* <li className="text-base text-gray-700 flex">
                  <Typography className="font-medium text-gray-500 mb-3 underline hover:cursor-pointer text-[#0d9488]">
                    policiy_2
                  </Typography>
                </li> */}
              </ul>
            </div>
          )}
        </div>
        <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <div className="flex flex-col items-start">
            <Typography
              variant="small"
              className="mb-4 font-normal text-blue-gray-900 md:mb-0"
            >
              &copy; {currentYear} Echo QR. {t("footer.allRightsReserved")}.
            </Typography>
            <Typography
              variant="small"
              className="mb-4 font-normal text-blue-gray-900 md:mb-0"
            >
              NIP: 5214029742 | REGON: 525957415
            </Typography>
          </div>

          <div className="flex gap-4 sm:justify-center mt-4">
            {/* <Typography
              as="a"
              href="#"
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <TiktokIcon className="w-5 h-5" />
            </Typography> */}
            <Typography
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/echoqr__pl?igsh=c2dnYjhmcm4zMzky"
              className=""
            >
              <InstagramIcon className="w-8 h-8" />
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
