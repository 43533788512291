import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import Layout from "./containers/Layout/Layout";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import ProtectedAdminRoute from "./components/ProtectedAdminRoute/ProtectedAdminRoute";
import { Spinner } from "@material-tailwind/react";
const MainPage = lazy(() => import("./pages/MainPage/MainPage"));
const HowItWorks = lazy(() => import("./pages/HowItWorks/HowItWorks"));
const Prices = lazy(() => import("./pages/Prices/Prices"));
const AdminPage = lazy(() => import("./pages/AdminPage/AdminPage"));
const Edit = lazy(() => import("./pages/Edit/Edit"));
const Preview = lazy(() => import("./pages/Preview/Preview"));
const MainView = lazy(() => import("./pages/MainView/MainView"));
const AccountPage = lazy(() => import("./pages/AccountPage/AccountPage"));

export const PATH_NAMES = {
  home: "/",
  login: "/login",
  account: "/account",
  howitworks: "/howitworks",
  prices: "/prices",
  editWithId: "/:id/edit",
  edit: "/edit",
  previewWithId: "/:id/preview",
  preview: "/preview",
  viewWithId: "/:id/view",
  view: "/view",
  admin: "/admin",
};

const FallbackComponent = () => (
  <div className="flex flex-col justify-center items-center min-h-screen !bg-transparent">
    <Spinner className="w-10 h-10" />
  </div>
);

const routes = [
  {
    path: PATH_NAMES.home,
    element: <Layout />,
    children: [
      {
        path: PATH_NAMES.home,
        element: (
          <Suspense fallback={<FallbackComponent />}>
            <MainPage />
          </Suspense>
        ),
      },
      {
        path: PATH_NAMES.howitworks,
        element: (
          <Suspense fallback={<FallbackComponent />}>
            <HowItWorks />
          </Suspense>
        ),
      },
      {
        path: PATH_NAMES.prices,
        element: (
          <Suspense fallback={<FallbackComponent />}>
            <Prices />
          </Suspense>
        ),
      },
      {
        path: PATH_NAMES.admin,
        element: (
          <ProtectedAdminRoute>
            <Suspense fallback={<FallbackComponent />}>
              <AdminPage />
            </Suspense>
          </ProtectedAdminRoute>
        ),
      },
      {
        path: PATH_NAMES.editWithId,
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FallbackComponent />}>
              <Edit />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: PATH_NAMES.previewWithId,
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FallbackComponent />}>
              <Preview />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: PATH_NAMES.viewWithId,
        element: (
          <Suspense fallback={<FallbackComponent />}>
            <MainView />
          </Suspense>
        ),
      },
      {
        path: PATH_NAMES.account,
        element: (
          <ProtectedRoute>
            <Suspense fallback={<FallbackComponent />}>
              <AccountPage />
            </Suspense>
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={PATH_NAMES.home} replace />,
  },
];

export default routes;
