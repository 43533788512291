import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  IconButton,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import { toast } from "react-toastify";

import { PATH_NAMES } from "../../routes";
import { getMBsFromBytes } from "../../utils/getMBsFromBytes";
import { downloadBase64Image } from "../../utils/downloadBase64Image";
import BlockDescription from "../../components/BlockDescription/BlockDescription";
import { deleteQrCodeData, getQrCodeBase64 } from "../../api/firebase/media";
import { EDIT_BLOCK_STYLES } from "../../constants";
import EditIcon from "../../svg/EditIcon";
import DeliveryIcon from "../../svg/DeliveryIcon";
import OpenInNewTabIcon from "../../svg/OpenInNewTabIcon2";
import ContinueIcon from "../../svg/ContinueIcon";
import DeleteIcon from "../../svg/DeleteIcon";
import DownloadIcon from "../../svg/DownloadIcon";
import { isProduction } from "../../utils/isProduction";
import { useAnalyticsEvent } from "../../hooks/useAnalytics";
import { useTranslation } from "react-i18next";
// import { isTextEmpty } from "../../utils/isTextEmpty";

const QRCodeCard = ({ data }) => {
  const { t } = useTranslation();
  const { sendEvent } = useAnalyticsEvent();

  const navigate = useNavigate();

  const { id, uploadedMedia = [], qrCodeBase64Id, text } = data;

  const size = uploadedMedia.reduce((acc, media) => acc + media.fileSize, 0);

  const goToDetails = () => {
    sendEvent("click_go_to_qr_code_edit_page");

    navigate(`/${id}${PATH_NAMES.edit}?step=0`);
  };

  const goToOrderPlate = () => {
    sendEvent("click_go_to_qr_code_pay_page");

    navigate(`/${id}${PATH_NAMES.edit}?step=2`);
  };

  const [base64, setBase64] = useState(null);
  const [isBase64Loading, setIsBase64Loading] = useState(false);
  const [isImageDownloading, setIsImageDownloading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!qrCodeBase64Id) {
        return;
      }
      try {
        setIsBase64Loading(true);
        const result = await getQrCodeBase64(qrCodeBase64Id);
        setBase64(result.base64);
      } catch (error) {
        sendEvent("error_load_qr_code_image");

        toast.error(t("toastMessages.loadQRFailed"));
        console.log("error:", error);
      } finally {
        setIsBase64Loading(false);
      }
    })();
  }, [qrCodeBase64Id]);

  const onClickImageDownload = async () => {
    sendEvent("click_download_qr_code_image");

    try {
      setIsImageDownloading(true);
      downloadBase64Image(base64, id);
    } catch (error) {
      sendEvent("error_download_qr_code_image");

      console.log("error:", error);
    } finally {
      setIsImageDownloading(false);
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteQrCodeDataDialogOpened, setIsDeleteQrCodeDataDialogOpened] =
    useState(false);

  const openDeleteQrCodeDataDialog = () => {
    setIsDeleteQrCodeDataDialogOpened(true);
  };

  const deleteQrCodeDataHandler = async () => {
    sendEvent("click_delete_unfinished_qr_code");

    try {
      setIsDeleting(true);
      await deleteQrCodeData(id);

      toast.success(t("toastMessages.qrCodeDeleteSuccess"));
    } catch (err) {
      sendEvent("error_delete_unfinished_qr_code");

      toast.error(t("toastMessages.qrCodeDeleteFailed"));
      console.log("error:", err);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <BlockDescription text={`ID: ${id ?? "-"}`} className="text-base" />
      <div className={`${EDIT_BLOCK_STYLES} !px-6 !py-4 !mx-0 !my-1`}>
        {qrCodeBase64Id && (
          <div className="flex w-full justify-center mb-6">
            {isBase64Loading ? (
              <Spinner className="h-12 w-12" />
            ) : (
              <div className="flex flex-col items-center gap-4">
                {(text?.fullName || "").trim() && (
                  <Typography color="gray">
                    {(text?.fullName || "").trim()}
                  </Typography>
                )}

                <img src={base64} alt="QR code" className="max-w-[200px]" />
                <div className="flex gap-4 justify-center items-end flex-wrap">
                  <Button
                    size="sm"
                    onClick={onClickImageDownload}
                    disabled={isImageDownloading}
                    loading={isImageDownloading}
                    className="whitespace-nowrap flex items-center gap-4"
                  >
                    {t("qrCodeCard.downloadQRImage")}
                    <DownloadIcon className="w-4 h-4" />
                  </Button>
                  <a
                    href={
                      isProduction()
                        ? `https://echoqr.pl/${qrCodeBase64Id}${PATH_NAMES.view}`
                        : `https://echo-project-bc60a.firebaseapp.com/${qrCodeBase64Id}${PATH_NAMES.view}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      size="sm"
                      className="whitespace-nowrap flex items-center gap-4"
                    >
                      {t("qrCodeCard.whereItLeadsTo")}
                      <OpenInNewTabIcon className="w-4 h-4" />
                    </Button>
                  </a>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex gap-x-6 gap-y-6 flex-wrap w-full">
          <div>
            <Typography color="black" className="">
              {t("qrCodeCard.usedMediaSize")}
            </Typography>
            <Typography color="gray">{getMBsFromBytes(size)}MB</Typography>
          </div>

          {/* <div>
            <Typography color="black" className="">
              {t("qrCodeCard.textDataExists")}
            </Typography>
            <Typography color="gray">
              {isTextEmpty(text) ? t("qrCodeCard.no") : t("qrCodeCard.yes")}
            </Typography>
          </div> */}

          {!qrCodeBase64Id && (
            <div>
              <Typography color="black" className="">
                {t("qrCodeCard.orderStatus")}
              </Typography>
              <Typography color="amber" className="font-semibold">
                {t("qrCodeCard.unfinished")}
              </Typography>
            </div>
          )}

          <div
            className={`w-full flex gap-4 ${
              qrCodeBase64Id ? "justify-end" : "justify-between"
            } items-end mt-6`}
          >
            {!qrCodeBase64Id && (
              <IconButton
                size="sm"
                variant="outlined"
                className="rounded-full"
                disabled={isDeleting}
                onClick={openDeleteQrCodeDataDialog}
              >
                {isDeleting ? (
                  <Spinner className="w-5 h-5" />
                ) : (
                  <DeleteIcon className="w-5 h-5" />
                )}
              </IconButton>
            )}
            <div className="flex gap-4 justify-end items-end flex-wrap">
              {qrCodeBase64Id && (
                <>
                  <Button
                    size="sm"
                    onClick={goToDetails}
                    className="whitespace-nowrap flex items-center gap-4"
                    variant="outlined"
                  >
                    {t("qrCodeCard.editButtonLabel")}
                    <EditIcon className="w-4 h-4" />
                  </Button>
                  {/* <Button
                    size="sm"
                    onClick={goToOrderPlate}
                    className="whitespace-nowrap flex items-center gap-4"
                    variant="outlined"
                  >
                    {t("qrCodeCard.orderAnotherPlaque")}
                    <DeliveryIcon className="w-4 h-4" />
                  </Button> */}
                </>
              )}

              {!qrCodeBase64Id && (
                <Button
                  size="sm"
                  onClick={goToDetails}
                  color="amber"
                  className="whitespace-nowrap flex items-center gap-4"
                >
                  {t("qrCodeCard.continueCreation")}
                  <ContinueIcon className="w-4 h-4" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={isDeleteQrCodeDataDialogOpened}
        handler={() => {
          setIsDeleteQrCodeDataDialogOpened(false);
        }}
      >
        <DialogBody className="flex w-full justify-center text-center">
          {t("qrCodeCard.deleteQrCodeDialog.text")}
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => {
              setIsDeleteQrCodeDataDialogOpened(false);
            }}
            className="!cursor-pointer mr-1"
            loading={isDeleting}
            disabled={isDeleting}
          >
            <span>{t("qrCodeCard.deleteQrCodeDialog.cancelButtonLabel")}</span>
          </Button>
          <Button
            size="sm"
            onClick={deleteQrCodeDataHandler}
            className="!cursor-pointer"
            loading={isDeleting}
            disabled={isDeleting}
          >
            <span>{t("qrCodeCard.deleteQrCodeDialog.deleteButtonLabel")}</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default QRCodeCard;
