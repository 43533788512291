import JSZip from "jszip";
import { saveAs } from "file-saver"; // This is for saving the generated zip file

export const downloadBase64Image = (base64Data, qrCodeId) => {
  // Create a link element, hide it, direct it towards the "href" that is the base64 encoded image
  const link = document.createElement("a");
  link.href = base64Data;
  link.download = `${qrCodeId}.png`; // The file name for download

  // Append to the DOM and trigger the download
  document.body.appendChild(link);
  link.click();

  // Remove the link when done
  document.body.removeChild(link);
};

// Function to download multiple Base64 images as a zip file
export const downloadBase64ImageArray = (idToBase64Map, zipFileName) => {
  const zip = new JSZip();
  const imgFolder = zip.folder(zipFileName);

  Object.entries(idToBase64Map).forEach(([qrCodeId, base64Data]) => {
    const imgName = `${qrCodeId}.png`;
    imgFolder.file(imgName, base64Data.split(",")[1], { base64: true });
  });

  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${zipFileName}.zip`);
  });
};
